const handleDateChange = (event, date, setDate) => {
    const selectedDate = event.target.value;
    const [year, month, day] = selectedDate.split('-');

    const updatedDate = new Date(date);
    updatedDate.setFullYear(parseInt(year), parseInt(month) - 1, parseInt(day));

    setDate(updatedDate);
};

export default handleDateChange;