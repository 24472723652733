import React, { useState, useEffect, useMemo } from 'react';

import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

import { Box } from '@mui/material';

import getPermission from '../../auth/GetPermission.js';
import getCities from '../../api/Location/getCities.js';

import AddButton from '../../Common/AddButton.js'

import './css/CountriesPage.css';

import Spinner from 'react-bootstrap/Spinner';
import CitiesNewModal from './CitiesNewModal.js';
import CitiesEditModal from './CitiesEditModal.js';
import deleteCity from '../../api/Location/deleteCity.js';
import GeneralTable from '../../Hooks/Table/GeneralTable.js';
import DeleteModal from '../../Common/DeleteModal';
import ErrorCases from '../../Common/ErrorCases.js';
import Footer from '../../Common/Footer.js';

function Cities() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [elementsPerPage] = useState(100);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [state, setState] = useState([]);
    const [stateId, setStateId] = useState(0);
    const [cityId, setCityId] = useState(0);
    const [city, setCity] = useState([]);
    const language = JSON.parse(localStorage.getItem('language'));
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingCity, setDeletingCity] = useState([]);
    const [deleteError, setDeleteError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData();
                setData(response);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, []);

    async function getData() {
        const { data, error } = await getCities(currentUser.data.currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    }

    const handleEditClick = (id) => {
        setCityId(id);
        const cityToEdit = data.find((city) => city.cityId === id);
        setCity({ name: cityToEdit.cityName, stateId: cityToEdit.stateId, abbreviation: cityToEdit.cityAbbreviation, tenantsDB: currentUser.data.currentTenant });
        setShowEditModal(true);
    };

    async function handleDeleteClick(id) {
        try {
            setIsLoading(true);
            const cityToDelete = { id: id, tenantsDB: currentUser.data.currentTenant };
            const response = await deleteCity(cityToDelete);
            if(response.error !== '') {
                const error = ErrorCases(response.error);
                setDeleteError(error);
                return;
            }
            const newData = await getData();
            setData(newData);
            setShowDeleteModal(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }

    }

    const deleteConfirmation = (city) => {
        setDeleteError('');
        setDeletingCity(city);
        setShowDeleteModal(true);
    };

    const columns = useMemo(
        () => [
            {
                header: language.city,
                accessorKey: 'cityName',
            },
            {
                header: language.Abbreviation,
                accessorKey: 'cityAbbreviation',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: language.state,
                accessorKey: 'stateName',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            }
        ],
        [],
    );

    const rowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
            <button
                className="btn"
                onClick={() => handleEditClick(row.original.cityId)}
                disabled={!getPermission(currentUser, "LocationCities", "updateAllow")}>
                <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
            </button>
            <button
                className="btn"
                onClick={() => deleteConfirmation(row.original)}
                disabled={(!getPermission(currentUser, "LocationCities", "deleteAllow"))}>
                <FontAwesomeIcon icon={faTrashCan} className='table__icon svg-icon' />
            </button>
        </Box>
    );

    return (
        <div className='main-container-app'>
            <h1 className='module__title'>{language.Cities}</h1>

            {
                getPermission(currentUser, "LocationCities", "readAllow") &&
                <>
                    <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions} />
                    <CitiesNewModal
                        show={showNewModal}
                        onHide={() => setShowNewModal(false)}
                        getData={getData}
                        setData={setData}
                    />
                    <CitiesEditModal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        editingCity={city}
                        editingCityId={cityId}
                        getData={getData}
                        setData={setData}
                    />
                </>
            }
            {
                showDeleteModal &&
                <DeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    message={language.DeleteCityMessage + deletingCity.cityName}
                    handleDelete={() => handleDeleteClick(deletingCity.cityId)}
                    error={deleteError}
                />
            }

            {
                getPermission(currentUser, "LocationCities", "writeAllow") &&
                <div className='addbutton__container' onClick={() => setShowNewModal(true)}><AddButton /></div>
            }

            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
            <Footer/>
        </div>

    );
}

export default Cities;