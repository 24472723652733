import React, { useState } from 'react';
import GroupTable from "../../../../Hooks/Table/GroupTable";
import { RiErrorWarningFill } from "react-icons/ri";

const DashboardTableAlarm = ({ device_data, header, handleDetails, language }) => {
    const columns_color = [{
        'InService': 'InService_color'
    }];
    const [isGrouped, setIsGrouped] = useState(false);

    const columns_table = [
    {
        header: language.totalAlarm,
        accessorKey: 'totalAlarm',
        Cell: ({ cell }) => {
            const value = cell.getValue();
            return (
                <div>
                    {value > 0 && (
                        <RiErrorWarningFill style={{ marginRight: '2px' }} color="orange" size={24} />
                    )}
                </div>
            );
        },
        enableColumnPinning: true,
        muiTableBodyCellProps: {
            align: 'center',
            sx: {
                // position: 'sticky',
                // left:  0,
                // zIndex: 1,
                backgroundColor: 'white',
            },
        },
        // muiTableHeadCellProps: {
        //     sx: {
        //         position: 'sticky',
        //         left:  0,
        //         zIndex: 2, // Mayor zIndex para que los headers estén por encima de las celdas
        //         top: 0, // Asegura que el encabezado también se quede fijo al hacer scroll vertical
        //         backgroundColor: 'white',
        //     },
        // },
    },
    {
        header: language.nameStation,
        accessorKey: 'nameStation',
        enableColumnPinning: true,
        // muiTableBodyCellProps: {
        //     sx: {
        //         position: 'sticky',
        //         left: 110,
        //         zIndex: 1,
        //         backgroundColor: 'white',
        //     },
        // },
        // muiTableHeadCellProps: {
        //     sx: {
        //         position: 'sticky',
        //         left: 110,
        //         zIndex: 2,
        //         top: 0,
        //         backgroundColor: 'white',
        //     },
        // },
    },
    {
        header: 'TVM',
        accessorKey: 'nameTVM',
        enableColumnPinning: true,
        muiTableBodyCellProps: {
            sx: {
                // position: 'sticky',
                // left: 320,
                // zIndex: 1,
                backgroundColor: 'white',
            },
        },
        // muiTableHeadCellProps: {
        //     sx: {
        //         position: 'sticky',
        //         left: 320,
        //         zIndex: 2,
        //         top: 0,
        //         backgroundColor: 'white',
        //     },
        // },
        Cell: ({ row }) => {
            const nameTVM = row.getValue('nameTVM');
            const TVMNumber = row.original.TVMNumber;
            return (
                <div>
                    {nameTVM} - {TVMNumber}
                </div>
            );
        },
    },
    {
        header: language.TVMVersion,
        accessorKey: 'TVMVersion',
        muiTableBodyCellProps: {
            sx: {
                // position: 'sticky',
                // left: 320,
                // zIndex: 1,
                backgroundColor: 'white',
            },
        },
    },
    {
        header: language.TVMInService,
        accessorKey: 'InService',
    },
];



    header.map(item => {
        if (item.deviceCode !== 'TVMInService') {
            columns_table.push({
                header: language[item.deviceCode],
                accessorKey: item.deviceCode
            });

            const colorKey = `${item.deviceCode}_color`;
            columns_color.push({
                [item.deviceCode]: colorKey
            });
        }
    });

    // header.map(item => {
    //     if (item.deviceCode !== 'TVMInService') {
    //         columns_table.push({
    //             header: language[item.deviceCode],
    //             accessorKey: item.deviceCode
    //         });

    //         const colorKey = `${item.deviceCode}_color`;
    //         columns_color.push({
    //             [item.deviceCode]: colorKey
    //         });
    //     } 

    //     // Verificar si accessorKey es "nameTVM"
    //     if (item.accessorKey === "nameTVM") {
    //         // Buscar el objeto correspondiente en columns_table
    //         const column = columns_table.find(col => col.accessorKey === item.deviceCode);
    //         if (column) {
    //             column.enableColumnPinning = true;
    //         }
    //     }
    // });

    return (
        <GroupTable device_data={device_data} columns_table={columns_table} columns_color={columns_color} handleDetails={handleDetails} language={language} setIsGrouped={setIsGrouped} />
    );
}

export default DashboardTableAlarm;