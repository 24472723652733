import Modal from 'react-bootstrap/Modal';
import { Button, Form, Tab, Tabs } from 'react-bootstrap';
import { saveAs } from 'file-saver';

import { useState, useEffect } from 'react';

import getStations from '../../../api/Location/getStations';
import getLabelsTable from '../../../api/TVM/GetLabelsTable';
import setTVM from '../../../api/TVM/setTVM';
import { Ports } from './Ports';

import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";

import CurrencyInput from 'react-currency-input-field';
import getGlobalConfig from '../../../api/TVM/getGlobalConfig';
import ErrorCases from '../../../Common/ErrorCases';
import { text } from '@fortawesome/fontawesome-svg-core';
import "./css/TVMNewModal.css";

function TVMNewModal({ show, onHide, getData, setData, setIsLoading }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const languageCode = currentUser.data.userData.languageCode;
    const currency = currentUser.data.catalog.currency[0];
    const [userTVM, setUserTVM] = useState({
        username: '',
        password: '',
        TVMName: '',
        TVMNumber: 0,
        version: '1.0',
        macAddress: '',
        idStation: 0,
        isActive: 1,
        maximumChangeAvailable: 200,
        alarmDelay: 0,
        screenTimeout: 60,
        soundVolume: 50,
        tenantsDB: currentTenant
    });
    const [stationsData, setStationsData] = useState([]);
    const [error, setError] = useState(null);
    const [passwordType, setPasswordType] = useState("password");
    const [verificationPasswordType, setVerificationPasswordType] = useState("password");
    const [showDevices, setShowDevices] = useState(false);
    const [labelsData, setLabelsData] = useState([]);
    const [maximumChange, setMaximumChange] = useState(200.00);
    const language = JSON.parse(localStorage.getItem('language'));
    const [dataError, setDataError] = useState('');
    const [createError, setCreateError] = useState('');
    const [numberError, setNumberError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                clearTVM();
                const response = await getStationsData();
                setStationsData(response);
                const labelsResponse = await getLabelsData();
                setLabelsData(labelsResponse);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, []);

    async function getStationsData() {
        const { data, error } = await getStations(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    }

    async function getLabelsData() {
        const { data, error } = await getLabelsTable(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            const coins = [];
            const bills = [];
            const devices = [];
            data.BillsLabel.map(bill => {
                bills.push({
                    idTvmBills: bill.idTvmBills,
                    label: bill.labelName,
                    EnableBills: 1
                });
            }
            );
            data.coinsLabel.map(coin => {
                coins.push({
                    idTvmCoins: coin.idTvmCoins,
                    label: coin.labelName,
                    EnableCoins: 1
                });
            }
            );
            data.DevicesLabel.map(device => {
                devices.push({
                    idDeviceAlarms: device.idDeviceAlarms,
                    name: device.name,
                    enableDevice: 1,
                    Port: 'none'
                });
            }
            );
            setUserTVM({
                ...userTVM,
                tenantsDB: currentTenant,
                isActive: 1,
                coinsList: coins,
                billsList: bills,
                deviceList: devices
            });
            return data;
        }
    }

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const newTVM = userTVM;
            newTVM.maximumChangeAvailable = maximumChange;
            const isDataValid = validateData(newTVM);
            if (isDataValid) {
                const response = await setTVM(newTVM);
                if (response.error !== undefined) {
                    const error = ErrorCases(response.error);
                    setDataError(error);
                    return;
                }
                const data = await getData();
                setData(data);
                onHide();
                clearTVM();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleStartupFileAndSave = async () => {
        setIsLoading(true);
        const newTVM = userTVM;
        newTVM.maximumChangeAvailable = maximumChange;
        const isDataValid = validateData(newTVM);
        if (isDataValid) {
            const response = await setTVM(newTVM);
            const { data } = await getGlobalConfig(currentTenant);
            const EndPointWS = data.globalConfig[0].EndPointWS;
            const station = stationsData.find(station => station.idStation === parseInt(newTVM.idStation));
            const startup = {
                idTvm: response.TVM,
                userName: newTVM.username,
                passwordTVM: response.passwordEncrypt,
                TVMNumber: newTVM.TVMNumber,
                tenantsDBName: currentTenant,
                idStation: newTVM.idStation,
                stations: station?.stationName,
                EndPointWS: EndPointWS
            }
            const xmlData = jsonToXml(startup, 'tvmConfig');
            downloadXMLFile(xmlData, 'Startup.xml');
            const reloadData = await getData();
            setData(reloadData);
            onHide();
            clearTVM();
        }
        setIsLoading(false);
    };

    const handleCancel = () => {
        onHide();
        clearTVM();
    };

    const changePasswordType = (isVerification) => {
        if (isVerification) {
            verificationPasswordType === "password" ? setVerificationPasswordType("text") : setVerificationPasswordType("password");
        } else {
            passwordType === "password" ? setPasswordType("text") : setPasswordType("password");
        }
    };

    const clearTVM = () => {
        setUserTVM({
            ...userTVM,
            username: '',
            password: '',
            TVMName: '',
            TVMNumber: 0,
            version: '1.0',
            macAddress: '',
            msgOutOfService:'',
            idStation: 0,
            isActive: 1,
            maximumChangeAvailable: 200,
            alarmDelay: 0,
            screenTimeout: 60,
            soundVolume: 50,
            tenantsDB: currentTenant
        });
        setDataError('');
        setShowDevices(false);
    };

    const validateData = (tvm) => {
        if (tvm.username === '' ||
            tvm.password === '' ||
            tvm.TVMName === '' ||
            tvm.TVMNumber === 0 ||
            tvm.macAddress === '' ||
            tvm.idStation === 0
        ) {
            setDataError('Please, fill the white spaces.')
            return false;
        } else { return true }
    }

    const handleChangeChecked = (table, key, id, enable) => {
        const newTable = [...userTVM[table]];
        const newCheckIndex = newTable.findIndex(check => check[key] === id);
        if (newCheckIndex !== -1) {
            newTable[newCheckIndex] = {
                ...newTable[newCheckIndex],
                [enable]: newTable[newCheckIndex][enable] === 1 ? 0 : 1
            };
            setUserTVM(prevState => ({
                ...prevState,
                [table]: newTable
            }));
        }
    };

    const handleAssignPort = (idDevice, port) => {
        setDataError('');
        const newTable = userTVM.deviceList;

        const isPortTaken = newTable.some(device => device.Port === port);
        if (isPortTaken) {
            setDataError(language.PortSelected);
            return;
        }

        const newPortIndex = newTable.findIndex(device => device.idDeviceAlarms === idDevice);
        if (newPortIndex !== -1) {
            newTable[newPortIndex] = {
                ...newTable[newPortIndex],
                "Port": port
            };
            setUserTVM(prevState => ({
                ...prevState,
                deviceList: newTable
            }));
        }
    };

    function jsonToXml(json, rootElement = 'root') {
        let xml = `<${rootElement}>`;

        for (const key in json) {
            if (json[key] instanceof Object && !Array.isArray(json[key])) {
                xml += jsonToXml(json[key], key);
            } else if (Array.isArray(json[key])) {
                json[key].forEach(item => {
                    xml += jsonToXml(item, key);
                });
            } else {
                xml += `<${key}>${json[key]}</${key}>`;
            }
        }

        xml += `</${rootElement}>`;
        return xml;
    }

    function downloadXMLFile(xmlContent, fileName) {
        const blob = new Blob([xmlContent], { type: 'application/xml' });
        saveAs(blob, fileName);
    }

    const handleChangeNumber = (e) => {
        const inputValue = e.target.value;

        if (inputValue >= 1 && inputValue <= 99999) {
            setUserTVM({ ...userTVM, TVMNumber: inputValue });
        }
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Body>
                    <div className="modal-scroll-container">
                        <Tabs defaultActiveKey="general" id="modal-tabs" className="mb-3 modal-tabs active-title">
                            <Tab eventKey="general" title={language.General}>

                                <div className='modal__subtitle'>{language.CreateUserTVM}</div>
                                <div className='inputs-row'>
                                    <div className='input-name'>
                                        <label className='input__label'>{language.TVMName}</label> <br />
                                        <input placeholder={language.EnterTVMName}
                                            className='module__input-search'
                                            onChange={evt => setUserTVM({ ...userTVM, TVMName: evt.target.value })}
                                        ></input>
                                    </div>

                                    <div className=''>
                                        <label className='input__label'>{language.Station}</label> <br />
                                        <select onChange={evt => setUserTVM({ ...userTVM, idStation: evt.target.value })}
                                            className='module__input-search'>
                                            <option>{language.SelectAStation}</option>
                                            {stationsData.length > 0 && stationsData.map(station => {
                                                return <option key={station.idStation} id={station.idStation} value={station.idStation}>{station.stationName}</option>
                                            })}
                                        </select>
                                    </div>


                                </div>
                                    <div className='inputs-row'>
                                    <div className='modal__tvm-number-input'>
                                        <label className='input__label'>{language.TVMNumber}</label> <br />
                                        <input
                                            className='module__input-search'
                                            onChange={handleChangeNumber}
                                            value={userTVM.TVMNumber}
                                            type='number'
                                        ></input>
                                    </div>

                                    <div className='input-name mx-3'>
                                        <label className='input__label'>{language.UserName}</label> <br />
                                        <input placeholder={language.EnterUsername}
                                            className='module__input-search'
                                            onChange={evt => setUserTVM({ ...userTVM, username: evt.target.value })}
                                        ></input>
                                    </div>

                                    <div className='input-name'>
                                        <label className='input__label'>{language.Password}</label> <br />
                                        <div className='password-input'>
                                            <input
                                                className='module__input-search'
                                                onChange={evt => setUserTVM({ ...userTVM, password: evt.target.value })}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className='error-alert'>{numberError}</div>
                                <div className='inputs-row__no-justified'>
                                    <div className='input-name'>
                                        <label className='input__label'>{language.MACAddress}</label> <br />
                                        <input placeholder={language.EnterMACAddress}
                                            className='module__input-search'
                                            onChange={evt => setUserTVM({ ...userTVM, macAddress: evt.target.value })}
                                        ></input>
                                    </div>
                                    <div className='input-name'>
                                        <label className='input__label'>{language.msgOutOfService}</label> <br />
                                        <input placeholder={language.msgOutOfService}
                                            className='module__input-search'
                                            maxLength={100}
                                            onChange={evt => setUserTVM({ ...userTVM, msgOutOfService: evt.target.value })}
                                        ></input>
                                    </div>
                                    <div >
                                        <label className='input__label'>{language.Active}</label> <br />
                                        <img src={userTVM.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                            className='table__checkbox'
                                            onClick={() => userTVM.isActive === 1 ? setUserTVM({ ...userTVM, isActive: 0 }) : setUserTVM({ ...userTVM, isActive: 1 })} />
                                    </div>
                                </div>

                            </Tab>
                            <Tab eventKey="devices" title={language.Devices}>
                                <div className='modal__subtitle'>{language.CashConfiguration}</div>
                                <div className='inputs-row'>
                                    <div className='input-name'>
                                        <label className='input__label'>{language.Coins}</label> <br />
                                        <div className='coins-input'>
                                            {userTVM.coinsList && userTVM.coinsList.map((element) => {
                                                return (
                                                    <div id='idTvmCoins' className='coin-box'>
                                                        <div className='modal__denomination'>{element.label}</div>
                                                        <img src={element.EnableCoins === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                                            className='modal__checkbox'
                                                            onClick={() => handleChangeChecked('coinsList', 'idTvmCoins', element.idTvmCoins, 'EnableCoins')} />
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>

                                        <label className='input__label'>{language.Bills}</label> <br />
                                        <div className='coins-input'>
                                            {userTVM.billsList && userTVM.billsList.map((element) => {
                                                return (
                                                    <div id='idTvmBills' className='coin-box'>
                                                        <div className='modal__denomination'>{element.label}</div>
                                                        <img src={element.EnableBills === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                                            className='modal__checkbox'
                                                            onClick={() => handleChangeChecked('billsList', 'idTvmBills', element.idTvmBills, 'EnableBills')} />
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>

                                        <label className='input__label'>{language.MaximumChangeAvailable}</label> <br />
                                        <CurrencyInput
                                            placeholder={language.EnterProductPrice}
                                            className='module__input-search'
                                            defaultValue={200.00}
                                            decimalsLimit={2}
                                            onValueChange={(value, names, values) => setMaximumChange(values.float)}
                                            decimalScale={2}
                                            maxLength={7}
                                            intlConfig={{ locale: languageCode, currency: currency.currency }}
                                        />
                                    </div>
                                </div>
                                <hr class="modal-separator"></hr>
                                <div className='modal__subtitle'>{language.TVMConfiguration}</div>
                                <div className='inputs-row'>

                                    <div>
                                        <label className='input__label'>{language.AlarmDelay}</label> <br />
                                        <input type='number'
                                            className='module__input-search'
                                            defaultValue={0}
                                            onChange={evt => setUserTVM({ ...userTVM, alarmDelay: evt.target.value })}
                                        ></input>
                                    </div>

                                    <div>
                                        <label className='input__label'>{language.ScreenTimeout}</label> <br />
                                        <input type='number'
                                            className='module__input-search'
                                            defaultValue={60}
                                            onChange={evt => setUserTVM({ ...userTVM, screenTimeout: evt.target.value })}
                                        ></input>
                                    </div>


                                </div>

                                <div>
                                    <label className='input__label'>{language.SoundVolume}</label> <br />
                                    <div className='modal-range__box'>
                                        <div className='modal-range__control' onClick={() => setUserTVM({ ...userTVM, soundVolume: userTVM.soundVolume - 1 })}>-</div>
                                        <Form.Range
                                            onChange={evt => setUserTVM({ ...userTVM, soundVolume: evt.target.value })}
                                            defaultValue={50}
                                            className='modal-range'
                                            value={userTVM.soundVolume}
                                        />
                                        <div className='modal-range__value'>{userTVM.soundVolume}</div>
                                        <div className='modal-range__control' onClick={() => setUserTVM({ ...userTVM, soundVolume: userTVM.soundVolume + 1 })}>+</div>
                                    </div>

                                </div>

                                <label className='input__label'>{language.AlarmsTable}</label> <br />
                                <div className='coins-input'>
                                    {userTVM.deviceList && userTVM.deviceList.map((element) => {
                                        return (
                                            <div id='idDeviceAlarms' className='alarm-box'>
                                                <img src={element.enableDevice === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                                    className='modal__checkbox'
                                                    onClick={() => handleChangeChecked('deviceList', 'idDeviceAlarms', element.idDeviceAlarms, 'enableDevice')} />
                                                <div className='modal__denomination'>{element.name}</div>
                                                <select className='modal__port-input'
                                                    onChange={(evt) => handleAssignPort(element.idDeviceAlarms, evt.target.value)}
                                                    value={element.Port || ''}>
                                                    <option>{language.SelectAPort}</option>
                                                    {Ports.map(port => (
                                                        <option key={port} value={port}>{port}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                            </Tab>
                        </Tabs>
                        {dataError && <div className='error-alert'>{dataError}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' style={{ fontSize: '12px' }} onClick={handleStartupFileAndSave}>
                        {language.StartupFile}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TVMNewModal;