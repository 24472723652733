import GetAllLanguage from "../../api/Languages/getAllLanguage";
import getLanguageFile from "../../api/Languages/getLanguageFile";
import languagefile from "../../Common/LanguageFile.json"

const CurrentLanguageFile = async (currentUser) => {
    const currentTenant = currentUser.data.currentTenant;
    const languages = await GetAllLanguage(currentTenant);
    const codeLanguage = languages.data.find(language => language.id === currentUser.data.userData.languageId).abbreviation;
    const body = {
        tenantsDB: currentTenant,
        codeLanguage: codeLanguage,
        fileFlags: {
            languageFileEMS: true,
            languageFileTVM: false
        }
    };
    const language = await getLanguageFile(body);
    if(language && Object.keys(language).length > 0 && !language.languageEMS[0].error){
        localStorage.setItem('language', JSON.stringify(language.languageEMS[0]));
    } else {
        // localStorage.setItem('language', JSON.stringify(languagefile[codeLanguage][0]));
        console.error('error with language api')
    }
}

export default CurrentLanguageFile;