import { DndContext } from "@dnd-kit/core";
import { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { IoIosSettings } from "react-icons/io";
import compareDashboard from "../../api/Dashboard/compareDashboard";
import Droppable from "./Droppable/Droppable";

import useDragAndDrop from "../../Hooks/useDragAndDrop/useDragAndDrop";
import getDetailInfo from "../../api/Dashboard/getDetailInfo";
import DashBoardTable from "./Table/DashBoardTable";
import getDate from "../../Common/GetDate";
import getHour from "../../Common/GetHour";
import handleDateChange from "../../Common/HandleDateChange";
import handleTimeChange from "../../Common/HandleTimeChange";
import formatLocalDateTime from "../../Common/FormatLocalDateTime";
import Footer from "../../Common/Footer";

function CompareDashboard() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const currency = currentUser.data.catalog.currency[0];
    const languageCode = currentUser.data.userData.languageCode;
    const language = JSON.parse(localStorage.getItem('language'));
    const [programDates, setProgramDates] = useState(true);
    const [compareDates, setCompareDates] = useState(false);
    const [programFromDate, setProgramFromDate] = useState(() => {
        const initialDate = new Date();
        initialDate.setHours(0, 0, 0, 0);
        return initialDate;
    });
    const [programToDate, setProgramToDate] = useState(() => {
        const initialDate = new Date();
        initialDate.setHours(23, 59, 59, 999);
        return initialDate;
    });
    const [compareFromDate, setCompareFromDate] = useState(() => {
        const initialDate = new Date();
        initialDate.setHours(0, 0, 0, 0);
        return initialDate;
    });
    const [compareToDate, setCompareToDate] = useState(() => {
        const initialDate = new Date();
        initialDate.setHours(23, 59, 59, 999);
        return initialDate;
    });
    const [datesError, setDatesError] = useState('');
    const [transactionsData, setTransactionsData] = useState([]);
    const [viewTransactions, setViewTransactions] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const initialItems1 = { dashboard1: [], dashboard2: [] };
    const initialItems2 = { dashboard3: [], dashboard4: [] };
    const { items: items1, sensors: sensors1, handleDragOver: handleDragOver1, handleDragEnd: handleDragEnd1, setItems: setItems1 } = useDragAndDrop(initialItems1);
    const { items: items2, sensors: sensors2, handleDragOver: handleDragOver2, handleDragEnd: handleDragEnd2, setItems: setItems2 } = useDragAndDrop(initialItems2);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState([]);
    const [data2, setData2] = useState([]);
    const [error2, setError2] = useState([]);
    const [isDraggable, setIsDraggable] = useState(false);
    const [isDraggableFinancial, setIsDraggableFinancial] = useState(false);

    const selectProgramDates = () => {
        setProgramDates(true);
        setCompareDates(false);
    };

    const selectCompareDates = () => {
        setProgramDates(false);
        setCompareDates(true);
    };

    function combineTotals(programData, compareData) {
        const combinedData = {};

        programData.forEach((item, index) => {
            const name = item.name;
            const total = item.total === null ? 0 : parseFloat(item.total);
            if (!combinedData[name]) {
                combinedData[name] = { id: index + 1, name: name, programTotal: 0, compareTotal: 0 };
            }
            combinedData[name].programTotal = total;
        });

        compareData.forEach((item, index) => {
            const name = item.name;
            const total = item.total === null ? 0 : parseFloat(item.total);
            if (!combinedData[name]) {
                combinedData[name] = { id: index + 1, name: name, programTotal: 0, compareTotal: 0 };
            }
            combinedData[name].compareTotal = total;
        });
        return Object.values(combinedData);
    };

    function combineTotalsAlarms(programData, compareData) {
        const combinedData = {};
        programData.forEach((item, index) => {
            const id = item.idDevice;
            const deviceCode = item.deviceCode;
            const alarmType = item.alarmType;
            const totalRegister = item.totalRegister;
            if (!combinedData[deviceCode]) {
                combinedData[deviceCode] = { id: id, deviceCode: deviceCode, alarmType: alarmType, programTotalRegister: 0, compareTotalRegister: 0 };
            }
            combinedData[deviceCode].programTotalRegister = totalRegister;
        });

        compareData.forEach((item, index) => {
            const id = item.idDevice;
            const deviceCode = item.deviceCode;
            const alarmType = item.alarmType;
            const totalRegister = item.totalRegister;
            if (!combinedData[deviceCode]) {
                combinedData[deviceCode] = { id: id, deviceCode: deviceCode, alarmType: alarmType, programTotalRegister: 0, compareTotalRegister: 0 };
            }
            combinedData[deviceCode].compareTotalRegister = totalRegister;
        });

        return Object.values(combinedData);
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isDataValid = validateDates();
        if (isDataValid) {
            const body = {
                tenantsDB: currentTenant,
                cardFinancialProgram: {
                    fromDate: programFromDate.toISOString(),
                    toDate: programToDate.toISOString()
                },
                cardFinancialCompare: {
                    fromDate: compareFromDate.toISOString(),
                    toDate: compareToDate.toISOString()
                },
                cardAlarmProgram: {
                    fromDate: programFromDate.toISOString(),
                    toDate: programToDate.toISOString()
                },
                cardAlarmCompare: {
                    fromDate: compareFromDate.toISOString(),
                    toDate: compareToDate.toISOString()
                }
            }
            const response = await compareDashboard(body);
            const allItems = combineTotals(response.getFinancialProgramDate, response.getFinancialCompareDate);
            const dashboard1 = allItems.slice(0, 5);
            const dashboard2 = allItems.slice(5);
            setItems1({ dashboard1, dashboard2 });
            const allAlarmsItems = combineTotalsAlarms(response.getAlarmProgramDate, response.getAlarmCompareDate);
            const dashboard3 = allAlarmsItems.slice(0, 5);
            const dashboard4 = allAlarmsItems.slice(5);
            setItems2({ dashboard3, dashboard4 });
        }
        setIsLoading(false);
    };

    const validateDates = () => {
        if (programToDate < programFromDate || compareToDate < compareFromDate) {
            setDatesError('"From date" must be equal or earlier than "To date"');
            return false;
        } else {
            setDatesError('');
            return true;
        }
    };

    const dateFormat = (date) => {
        const formattedDate = date.toLocaleString('default', { year: 'numeric', month: '2-digit', day: '2-digit' });
        return formattedDate;
    };

    const handleCardDetails = async (idDevice, typesOfDetails) => {
        setIsLoading(true);
        setShowModal(true);
        try {
            const tenantsDB = currentTenant;
            const id = idDevice;
            const { data, error } = await getDetailInfo({ id, isRealTime: 0, toDate: programToDate.toISOString(), fromDate: programFromDate.toISOString(), tenantsDB, typesOfDetails })
            if (data) {

                setData(data);
            } else if (error) {
                setError(error);
            }
            const response = await getDetailInfo({ id, isRealTime: 0, toDate: compareToDate.toISOString(), fromDate: compareFromDate.toISOString(), tenantsDB, typesOfDetails: 'alarm_card' })
            const data2 = response.data;
            const error2 = response.error;
            if (data2) {

                setData2(data2);

            } else if (error2) {
                setError2(error2);
            }

        } catch (err) {
            setError(err);
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (isoDate, languageCode) => {
        const date = new Date(isoDate);
        return date.toLocaleDateString(languageCode, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    return (
        <div className="main-container-app">
            <h4 className='module__title'>{language.CompareDashboard}</h4>
            <div className="dashboard-container">
                <div className="dashboard-btn-container">
                    <button className={viewTransactions ? "dashboard-date-btn active" : "dashboard-date-btn"}
                        onClick={() => setViewTransactions(true)}>{language.Transactions}</button>
                    <button className={viewTransactions ? "dashboard-date-btn" : "dashboard-date-btn active"}
                        onClick={() => setViewTransactions(false)}>{language.totalAlarm}</button>
                </div>

            </div>
            {/* {dateBtn && (<p className="data_label">{dateBtn}</p>)} */}

            <div className="main-box" style={{minHeight: 'auto'}}>
                <div className="dashboard-settings">
                    {
                        (items1.length > 0) && (items1.dashboard1.length + items1.dashboard2.length) > 5 &&
                        (<IoIosSettings size={20} style={{ fill: '#727BBE' }} />)
                    }
                </div>
                <h4 className='dashboard-subtitle'>{language.SummaryTransactionsData}</h4>
                <div className="select-dates-section">
                    <div>
                        <div className="dashboard-btn-container" style={{ gap: '1rem' }}>
                            <div className={programDates ? "select-dates-text active" : "select-dates-text"}>{language.ProgramToDate}</div>
                            <div className={programDates ? 'main-box__submenu-element active date-field' : 'main-box__submenu-element inactive date-field'} onClick={selectProgramDates}>{programFromDate.toLocaleString()}</div>
                            <div className="select-dates-text" style={{ width: 'auto' }}>-</div>
                            <div className={programDates ? 'main-box__submenu-element active date-field' : 'main-box__submenu-element inactive date-field'} onClick={selectProgramDates}>{programToDate.toLocaleString()}</div>
                        </div>
                        <div className="dashboard-btn-container" style={{ gap: '1rem' }}>
                            <div className={compareDates ? "select-dates-text active" : "select-dates-text"}>{language.CompareToDate}</div>
                            <div className={compareDates ? 'main-box__submenu-element active date-field' : 'main-box__submenu-element inactive date-field'} onClick={selectCompareDates}>{compareFromDate.toLocaleString()}</div>
                            <div className="select-dates-text" style={{ width: 'auto' }}>-</div>
                            <div className={compareDates ? 'main-box__submenu-element active date-field' : 'main-box__submenu-element inactive date-field'} onClick={selectCompareDates}>{compareToDate.toLocaleString()}</div>
                        </div>
                        <div className='error-alert'>{datesError}</div>
                    </div>
                    {

                        // (customDate) && 
                        (<div className="dashboard-box-date">
                            <Form>
                                <Form.Group controlId="fromDate">
                                    <Form.Label className="select-dates-text">{language.FromDate}</Form.Label>
                                    <Form.Control
                                        type="date"
                                        // ref={fromDateRef}
                                        onChange={(event) => programDates
                                            ? handleDateChange(event, programFromDate, setProgramFromDate)
                                            : handleDateChange(event, compareFromDate, setCompareFromDate)}
                                        value={programDates ? getDate(programFromDate) : getDate(compareFromDate)}
                                    />
                                    <Form.Control
                                        type="time"
                                        onChange={(event) => programDates
                                            ? handleTimeChange(event, programFromDate, setProgramFromDate)
                                            : handleTimeChange(event, compareFromDate, setCompareFromDate)}
                                        value={programDates ? getHour(programFromDate) : getHour(compareFromDate)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="toDate">
                                    <Form.Label className="select-dates-text">{language.ToDate}</Form.Label>
                                    <Form.Control
                                        type="date"
                                        // ref={toDateRef}
                                        onChange={(event) => programDates
                                            ? handleDateChange(event, programToDate, setProgramToDate)
                                            : handleDateChange(event, compareToDate, setCompareToDate)}
                                        value={programDates ? getDate(programToDate) : getDate(compareToDate)}
                                    />
                                    <Form.Control
                                        type="time"
                                        onChange={(event) => programDates
                                            ? handleTimeChange(event, programToDate, setProgramToDate)
                                            : handleTimeChange(event, compareToDate, setCompareToDate)}
                                        value={programDates ? getHour(programToDate) : getHour(compareToDate)}
                                    />
                                </Form.Group>
                                <Button className="Submit_btn" onClick={handleSubmit} variant="primary">{language.Submit}</Button>
                            </Form>
                        </div>)
                    }
                </div>

                {viewTransactions ?

                    <DndContext
                        sensors={sensors1}
                        onDragEnd={handleDragEnd1}
                        onDragOver={handleDragOver1}
                    >
                        <div className={`cards-container ${isDraggableFinancial ? 'show-droppable' : ''}`}>
                            {(items1.dashboard1.length > 0 || items1.dashboard2.length > 0) &&
                                <div className="main-droppable">
                                    <Droppable
                                        id="dashboard1"
                                        items={items1.dashboard1}
                                        cardType={"Compare"}
                                        language={language}
                                        key="dashboard1"
                                        currentDashboard={"TVMDashboardCompare"}
                                        currency={currency}
                                        programFromDate={dateFormat(programFromDate)}
                                        programToDate={dateFormat(programToDate)}
                                        compareFromDate={dateFormat(compareFromDate)}
                                        compareToDate={dateFormat(compareToDate)}
                                        isDraggable={isDraggableFinancial}
                                        setIsDraggable={setIsDraggableFinancial}
                                    />
                                </div>
                            }

                            {
                                isDraggableFinancial && (
                                    <div className="droppable-wrapper"
                                        style={{ width: '25rem' }}>
                                        <Droppable
                                            id="dashboard2"
                                            items={items1.dashboard2}
                                            cardType={"Compare"}
                                            language={language}
                                            key="dashboard2"
                                            currentDashboard={"TVMDashboardCompare"}
                                            currency={currency}
                                            programFromDate={dateFormat(programFromDate)}
                                            programToDate={dateFormat(programToDate)}
                                            compareFromDate={dateFormat(compareFromDate)}
                                            compareToDate={dateFormat(compareToDate)}
                                            isDraggable={isDraggableFinancial}
                                            setIsDraggable={setIsDraggableFinancial}
                                            isMoreCards={true}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </DndContext>
                    :
                    <DndContext
                        sensors={sensors2}
                        onDragEnd={handleDragEnd2}
                        onDragOver={handleDragOver2}
                    >
                        <div className={`cards-container ${isDraggable ? 'show-droppable' : ''}`}>
                            {(items2.dashboard3.length > 0 || items2.dashboard4.length > 0) &&
                                <div className="main-droppable">
                                    <Droppable
                                        id="dashboard3"
                                        items={items2.dashboard3}
                                        cardType={"CompareAlarm"}
                                        language={language}
                                        handleCardDetails={handleCardDetails}
                                        key="dashboard3"
                                        currentDashboard={"TVMDashboardCompare"}
                                        currency={currency}
                                        programFromDate={programFromDate.toISOString()}
                                        programToDate={programToDate.toISOString()}
                                        compareFromDate={compareFromDate.toISOString()}
                                        compareToDate={compareToDate.toISOString()}
                                        setIsLoading={setIsLoading}
                                        showModal={showModal}
                                        setShowModal={setShowModal}
                                        isDraggable={isDraggable}
                                        setIsDraggable={setIsDraggable}
                                    />
                                </div>
                            }
                            {
                                isDraggable && (
                                    <div className="droppable-wrapper"
                                        style={{ width: '25rem' }}>
                                        <Droppable
                                            id="dashboard4"
                                            items={items2.dashboard4}
                                            cardType={"CompareAlarm"}
                                            language={language}
                                            handleCardDetails={handleCardDetails}
                                            key="dashboard4"
                                            currentDashboard={"TVMDashboardCompare"}
                                            currency={currency}
                                            programFromDate={programFromDate.toISOString()}
                                            programToDate={programToDate.toISOString()}
                                            compareFromDate={compareFromDate.toISOString()}
                                            compareToDate={compareToDate.toISOString()}
                                            setIsLoading={setIsLoading}
                                            showModal={showModal}
                                            setShowModal={setShowModal}
                                            isDraggable={isDraggable}
                                            setIsDraggable={setIsDraggable}
                                            isMoreCards={true}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </DndContext>
                }
            </div>
            <Footer/>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered size="xl" backdrop={false} animation={true} keyboard={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {language.AlarmsCompare}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '49%' }}>
                            <p className="compare-modal-dates-range" >
                                <span>{formatDate(programFromDate, language)} - {formatDate(programToDate, language)}</span>
                            </p>
                            {data.length > 0 ? <DashBoardTable device_data={data} language={language} /> :
                                <div className="compare-modal-no-registers">{language.NoRegisters}</div>}
                        </div>
                        <div style={{ width: '49%' }}>
                            <p className="compare-modal-dates-range" >
                                <span>{formatDate(compareFromDate, language)} - {formatDate(compareToDate, language)}</span>
                            </p>
                            {data2.length > 0 ? <DashBoardTable device_data={data2} language={language} /> :
                                <div className="compare-modal-no-registers">{language.NoRegisters}</div>}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </div>
    );
}

export default CompareDashboard;