import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import { Spinner } from "react-bootstrap";

import { MdOutlineHomeWork } from "react-icons/md";

import { getCurrentUser } from 'aws-amplify/auth';
import languages from './LoginLanguage.json';
import CustomAlert from "../Common/CustomAlert";

import CurrentLanguageFile from '../Hooks/language/CurrentLanguageFile';
import GetUser from '../api/EMSuser/getUser';

function SelectTenant() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenants = currentUser.data.tenantsUser;
    const [selectedTenant, setSelectedTenant] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);
    const currentLanguage = localStorage.getItem('currentLanguage');
    const language = languages[currentLanguage][0];
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      

        const currentLocalUser = JSON.parse(localStorage.getItem('currentUser'));

        ((authenticated && currentLocalUser.data.tenantsUser.length === 1) || 
            (authenticated && currentLocalUser.data.currentTenant !== '' && currentLocalUser.data.tenantsUser.length > 1))
            && navigate('/Home');

        (authenticated && currentLocalUser.data.currentTenant === '' && currentLocalUser.data.tenantsUser.length > 1) 
          && navigate('/select-tenant');
        
        setIsLoading(true);
        checkAuthentication();
        setIsLoading(false);
    }, [authenticated]);

    const checkAuthentication = async () => {
        try {
            const currentUser = await getCurrentUser();
            setAuthenticated(!!currentUser);
        } catch (error) {
            setAuthenticated(false);
        }
    };
    
    const handleLogin = async () => {
      setIsLoading(true);
        if(selectedTenant !== '') {
            const updatedCurrentUser = await GetUser(selectedTenant);
            // currentUser.data.currentTenant = selectedTenant;
            localStorage.setItem('currentUser', JSON.stringify(updatedCurrentUser));
            localStorage.setItem('currentLanguage', updatedCurrentUser.data.userData.languageAbbreviation);
            navigate('/Home');
            navigate(0);
        } else {
            setAlertMessage(language.SelectATenant);
            setShowAlert(true);
        }
      setIsLoading(false);
    };

    return (
        <>
          <div className="main-container">
            <img
                  loading="lazy"
                  src="./img/logoBEATT.png"
                  className="login__logo"
                />
            <Form.Select className="tenants__dropdown" type="text" placeholder={language.UserName}
                                    onChange={evt => setSelectedTenant(evt.target.value)} 
                                    >
                    <option className='option-text'>{language.SelectTenant}</option>
                    {currentTenants.map(tenant => {
                        return(<option key={tenant.tenantsKey} value={tenant.tenantsKey} className='tenants__dropdown-option'>{tenant.tenantsName}</option>)
                    })}
                  </Form.Select>
            

            <div className="button-light-blue" onClick={handleLogin}>{language.LOGIN}</div>
            {showAlert &&
                    <CustomAlert message={alertMessage}/>
                  }

          </div>
          {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner'/>
                </div>
          }
        </>
      );
}

export default SelectTenant;