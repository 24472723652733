import postMethod from "../../Hooks/API/post";

async function GetCatalog(body) {
    const path = `/catalog/GetCatalog`;
    try {
        const data = await postMethod(body, path);
        return data;
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
}

export default GetCatalog;