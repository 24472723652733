const calculateChangePercentage = (programTotal, compareTotal, currentLanguageCode) => {
    const program = isNaN(parseFloat(programTotal)) ? 0 : parseFloat(programTotal);
    const compare = isNaN(parseFloat(compareTotal)) ? 0 : parseFloat(compareTotal);

    if (compare === 0) {
        const percentageChange = program > 0 ? "-100%" : "0%";
        return percentageChange;
    }

    const changePercentage = ((program - compare) / compare) * 100;
    const symbol = program < compare ? '+' : '-';
    const formattedChangePercentage = Math.abs(changePercentage)
        .toLocaleString(currentLanguageCode, { maximumFractionDigits: 2 });

    return symbol + formattedChangePercentage + '%';
};

export default calculateChangePercentage;