import React from "react";
import { VscError } from "react-icons/vsc";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { MdOutlineWarningAmber } from "react-icons/md";
import { GoKebabHorizontal } from "react-icons/go";
import { AiOutlineDollarCircle } from "react-icons/ai";
import "./Cards.css";
import calculateChangePercentage from "../../../Common/CalculateChangePercentage";

const formatCurrency = (value, languageCode, currency) => {
    return new Intl.NumberFormat(languageCode, {
        style: 'currency',
        currency: currency.currency,
    }).format(value);
};

const CardCompare = ({ name, programTotal, compareTotal, typeIcon, id, languageCode, currency, language, isDragging, isDraggable, programFromDate, programToDate, compareFromDate, compareToDate }) => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentLanguageCode = currentUser.data.userData.languageCode;
    let icon;
    let color;
    switch (typeIcon) {
        case 'Financial':
            color = '#009A20';
            icon = <IoIosCheckmarkCircleOutline
                key={id}
                className="card-financial-icon"
                size={40}
                style={{ fill: color }} />;
            programTotal = formatCurrency(programTotal, languageCode, currency);
            compareTotal = formatCurrency(compareTotal, languageCode, currency);
            break;
        case 'Count':
            color = '#009A20';
            icon = <MdOutlineWarningAmber
                key={id}
                className="card-financial-icon"
                size={40}
                style={{ fill: color }} />;
            break;
        default:
            color = '#009A20';
            icon = <AiOutlineDollarCircle
                key={id}
                className="card-financial-icon"
                size={40}
                style={{ fill: color }} />;
    };

    return (
        <section className={`card-compare-container ${isDragging ? 'card-container-drag' : '', isDraggable ? '' : 'card-container-isDrag'}`}>
            <div className="card-alert-color" style={{ backgroundColor: color }} />
            <div className="card-information">
                <div className="card-alert">
                    {icon}
                </div>
                <div>
                    <div className="card-percentage">{calculateChangePercentage(programTotal, compareTotal, currentLanguageCode)}</div>
                    <p className="card-title">{language[name]}</p>
                    <p className="card-compare-status" style={{ marginTop: '1rem' }}>
                        <span>{programFromDate} - {programToDate}</span>
                        <span className="compare-total">{formatCurrency(programTotal, languageCode, currency)}</span>
                    </p>
                    <p className="card-compare-status" style={{ marginBottom: '1rem' }}>
                        <span>{compareFromDate} - {compareToDate}</span>
                        <span className="compare-total">{formatCurrency(compareTotal, languageCode, currency)}</span>
                    </p>
                </div>
            </div>
            <div className="card-menu-div">
                <GoKebabHorizontal className="card-menu" size={20} />
            </div>
        </section>
    );
}

export default CardCompare;