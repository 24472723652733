import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';
import { signOut } from 'aws-amplify/auth';
import { Spinner } from "react-bootstrap";
import GetPermission from './GetPermission';
import Swal from 'sweetalert2';

const PrivateRoute = ({ className, moduleName }) => {
    const [authenticated, setAuthenticated] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const language = JSON.parse(localStorage.getItem('language'));
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const currentUser = await getCurrentUser();
                setAuthenticated(!!currentUser);
                if (!!currentUser) {
                    localStorage.setItem('openSession', JSON.stringify(true));
                }
                const currentLocalUser = JSON.parse(localStorage.getItem('currentUser'));
                const permission = GetPermission(currentLocalUser, moduleName, "readAllow");
                setHasPermission(permission);
            } catch (error) {
                setAuthenticated(false);
                setHasPermission(false);
            }
        };

        checkAuthentication();
    }, [location, moduleName]);

    useEffect(() => {
        if (authenticated === false) {
            const isSessionExpired = JSON.parse(localStorage.getItem('tokenExpired'));
            const isOpenSession = JSON.parse(localStorage.getItem('openSession'));
            if (isSessionExpired || isOpenSession) {
                localStorage.removeItem('tokenExpired');
                localStorage.removeItem('openSession');
                handleSessionExpired();
            } else {
                navigate("/login");
            }
        }
    }, [authenticated]);

    const handleSessionExpired = async () => {
        const result = await Swal.fire({
            icon: 'warning',
            title: language.SessionExpired,
            text: language.YourSessionHasExpired,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            customClass: {
                title: 'module__title',
                text: 'card__label',
                confirmButton: 'general-button primary session-alert', 
            },
        });

        if (result.isConfirmed) {
            try {
                await signOut();
                navigate("/login");
                navigate(0);
            } catch (signOutError) {
                console.error('Error during sign-out:', signOutError);
            }
        }
    };

    if (authenticated === null) {
        return (
            <div className='spinner-container'>
                <Spinner animation="border" variant="primary" className='spinner' />
            </div>
        );
    }

    if (authenticated && hasPermission) {
        if(currentUser.data.tenantsUser.length > 1 && currentUser.data.currentTenant === '') {
            navigate("/select-tenant");
        } else {
            return <div className={className ?? 'app-container navbar-open'}><Outlet /></div>;
        }
        
    } else if (authenticated && !hasPermission) {
        return <Navigate to="/access-denied" />;
    }

    return null;
};

export default PrivateRoute;
