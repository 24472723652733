import React from "react";
import { VscError } from "react-icons/vsc";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { RiErrorWarningLine } from "react-icons/ri";
import { GoKebabHorizontal } from "react-icons/go";
import "./Cards.css";

const CardInformativeAlarm = ({badge,color,typeStatus,idDevice,handleCardDetails, language, item,isDragging,isDraggable}) =>{
    color = 'gray';
    const icon = <RiErrorWarningLine
        onClick={() => handleClick()}
        key={idDevice}
        className="card-alert-icon"
        size={40}
        style={{ fill: color }} />;

    const alert = (id,typeStatus='Ok') => {
        return (id==='NotificationPanic' || id ==='PanicButton') && typeStatus != 'Ok';
    }

    const handleClick = () => {
        if (badge !== 0) {
            handleCardDetails(idDevice,'alarm_card');
        }
    };
    return (
        <section className={`card-container ${isDragging? 'card-container-drag':'', isDraggable? '':'card-container-isDrag'}`}>
            <div className={`card-alert-color ${alert(item.id,typeStatus)?'':''}`}  
                style={{ backgroundColor: color, '--color': color }} />
                <div className="card-information">
                    <div className="card-alert">
                    <div>{icon}</div>
                    {
                        typeStatus !== 'Ok' && <div className="card-badge">{badge}</div>
                    }
                    </div>
                    <div>
                    <p className="card-title">{language[item.id]}</p>
                    <p className="card-status">{language.Status} : {language[typeStatus]}</p>
                    </div>
                </div>
                <div className="card-menu-div">
                    <GoKebabHorizontal className="card-menu" size={20} />
                </div>
            </section>
    );
}

export default CardInformativeAlarm;