import postMethod from "../../Hooks/API/post";

async function AssignTenant(body) {
    const path = '/Tenants/assignment';
    try {
        const { data, error } = await postMethod(body, path);
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default AssignTenant;