import GeneralTable from "../../../Hooks/Table/GeneralTable";
import getPermission from "../../../auth/GetPermission";
import AddButton from "../../../Common/AddButton";
import getAllEmailAlarms from "../../../api/EmailsAlarms/getAllEmailAlarms";
import EmailsNewModal from "./EmailsNewModal";
import EmailsEditModal from "./EmailsEditModal";
import deleteEmailAlarm from "../../../api/EmailsAlarms/deleteEmailAlarm";
import DeleteModal from "../../../Common/DeleteModal";

import { Box } from '@mui/material';

import { useState, useEffect } from "react";

import { Spinner } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import ErrorCases from "../../../Common/ErrorCases";
import Footer from "../../../Common/Footer";

function Emails() {
    const [isLoading, setIsLoading] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingEmail, setEditingEmail] = useState([]);
    const language = JSON.parse(localStorage.getItem('language'));
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingEmail, setDeletingEmail] = useState([]);
    const [deleteError, setDeleteError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData();
                setData(response);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, []);

    async function getData() {
        const { data, error } = await getAllEmailAlarms(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const columns = [
        {
            header: language.Name,
            accessorKey: 'name',
            minSize: '10%',
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: language.LastName,
            accessorKey: 'lastName',
            grow: true,
            minSize: 210,
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: language.Email,
            accessorKey: 'email',
            grow: true,
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: language.Active,
            accessorKey: 'isActive',
            Cell: ({ renderedCellValue }) => (
                <Box>
                    <img src={renderedCellValue === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                        className='table__checkbox' />
                </Box>
            ),
            grow: true,

        }
    ];

    const rowActions = ({ row }) => (
        <Box sx={{ display: 'flex' }}>
            <button
                className="btn"
                onClick={() => handleEditClick(row.original)}
                disabled={!getPermission(currentUser, "DMConfigEmailAlarms", "updateAllow")}>
                <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
            </button>

            <button
                className="btn"
                onClick={() => deleteConfirm(row.original)}
                disabled={(!getPermission(currentUser, "DMConfigEmailAlarms", "deleteAllow"))}>
                <FontAwesomeIcon icon={faTrashCan} className='table__icon svg-icon' />
            </button>
        </Box>
    );

    const handleEditClick = (email) => {
        setEditingEmail(email);
        setShowEditModal(true);
    };

    const handleDeleteClick = async (id) => {
        try {
            setIsLoading(true);
            const response = await deleteEmailAlarm(id, currentTenant);
            if (response.error !== undefined) {
                const error = ErrorCases(response.error);
                setDeleteError(error);
                return;
            }
            const newData = await getData();
            setData(newData);
            setShowDeleteModal(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }

    };

    const deleteConfirm = (email) => {
        setDeletingEmail(email);
        setShowDeleteModal(true);
        setDeleteError('');
    };

    return (
        <div className='main-container-app'>
            <h1 className='module__title'>{language.Emails}</h1>
            {getPermission(currentUser, "DMConfigEmailAlarms", "readAllow") &&
                <>

                    <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions} />
                    {
                        getPermission(currentUser, "DMConfigEmailAlarms", "writeAllow") &&
                        <div className='addbutton__container' onClick={() => setShowNewModal(true)}><AddButton /></div>
                    }

                    <EmailsNewModal
                        show={showNewModal}
                        onHide={() => setShowNewModal(false)}
                        getData={getData}
                        setData={setData}
                        setIsLoading={setIsLoading} />

                    <EmailsEditModal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        getData={getData}
                        setData={setData}
                        setIsLoading={setIsLoading}
                        editingEmail={editingEmail} />
                </>
            }

            {
                showDeleteModal &&
                <DeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    message={language.DeleteEmailMessage + deletingEmail.email}
                    handleDelete={() => handleDeleteClick(deletingEmail.idEmailAlarms)}
                    error={deleteError}
                />
            }

            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
            <Footer/>
        </div>
    );
}

export default Emails;