import postMethod from "../../Hooks/API/post";

async function editLanguageFile(body) {
    const path = '/Languages/languageFile';
    try {
        const res = await postMethod(body, path);
        return res;
    } catch (error) {
        return {error};
    }
}

export default editLanguageFile;