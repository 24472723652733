import Footer from "../../../Common/Footer";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from "react";
import GetAllLanguage from "../../../api/Languages/getAllLanguage";
import getLanguageFile from "../../../api/Languages/getLanguageFile";
import { Spinner } from "react-bootstrap";
import editLanguageFile from "../../../api/Languages/editLanguageFile";
import "./css/LanguagePage.css";

function LanguagePage() {
    const language = JSON.parse(localStorage.getItem('language'));
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [languageCode, setLanguageCode] = useState(localStorage.getItem('currentLanguage'));
    const [languagesData, setLanguagesData] = useState([]);
    const [languageValue, setLanguageValue] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState([]);
    const [selectedKey, setSelectedKey] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getLanguages();
        // assignLanguage(language);
    }, []);

    async function getLanguages() {
        try {
            setIsLoading(true);
            const languages = await GetAllLanguage(currentTenant);
            const defaultProps = {
                options: languages.data,
                getOptionLabel: (option) => option.name,
            };
            setLanguagesData(defaultProps);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const assignLanguage = (language) => {
        const formattedLanguage = Object.entries(language).map(([key, value]) => ({
            id: key,
            label: value
        }));
        setSelectedLanguage(formattedLanguage);
    };

    const handleSelectLanguage = async (value) => {
        if (!value || !value.abbreviation) return;

        setIsLoading(true);
        setLanguageValue(value);

        const body = {
            tenantsDB: currentTenant,
            codeLanguage: value.abbreviation,
            fileFlags: {
                languageFileEMS: true,
                languageFileTVM: false
            }
        };

        try {
            const language = await getLanguageFile(body);

            assignLanguage(language.languageEMS[0]);
            setLanguageCode(value.abbreviation);
            setSelectedKey([]);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelectKey = (key) => {
        setSelectedKey(key);
    };

    const handleSaveChanges = async () => {
        setIsLoading(true);
        const editedLanguage = selectedLanguage;
        editedLanguage.find(key => key.id === selectedKey.id).label = selectedKey.label;
        const formattedLanguage = editedLanguage.reduce((acc, item) => {
            acc[item.id] = item.label;
            return acc;
        }, {});
        const body = {
            tenantsDB: currentTenant,
            codeLanguage: languageCode,
            path: "languageFileEMS",
            jsonLanguage: [formattedLanguage]
        };
        const response = await editLanguageFile(body);
        setSelectedKey([]);
        setIsLoading(false);
    };

    return (
        <div className="main-container-app">
            <h1 className='module__title'>EMS Language</h1>
            <div className="main-box" style={{ flexGrow: 0 }}>
                <div className="selects-container">
                    <Autocomplete
                        {...languagesData}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={language.SelectLanguage}
                                variant="standard"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    sx: {
                                        borderRadius: '1rem',
                                        padding: '0 10px',
                                        bgcolor: 'transparent',
                                        height: '2rem',
                                        backgroundColor: '#EBEBEB',
                                    },
                                }}
                                InputLabelProps={{
                                    sx: {
                                        lineHeight: '2rem',
                                        fontSize: '0.875rem',
                                        zIndex: 10,
                                        marginLeft: '1rem'
                                    },
                                }}
                                sx={{ height: '2rem' }}
                            />
                        )}
                        onChange={(event, newValue) => {
                            handleSelectLanguage(newValue);
                        }}
                        sx={{
                            display: 'inline-block',
                            '& .MuiInputBase-input': {
                                padding: '0 10px',
                                height: '2rem',
                                boxSizing: 'border-box',

                            },
                            width: '11rem',
                            borderRadius: '1rem',
                            border: 'none',

                        }}
                    />
                    <Autocomplete
                        options={selectedLanguage || []}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={language.TypeOrSelect}
                                variant="standard"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    sx: {
                                        borderRadius: '1rem',
                                        padding: '0 10px',
                                        bgcolor: 'transparent',
                                        height: '2rem',
                                        backgroundColor: '#EBEBEB',
                                    },
                                }}
                                InputLabelProps={{
                                    sx: {
                                        lineHeight: '2rem',
                                        fontSize: '0.875rem',
                                        zIndex: 10,
                                        marginLeft: '1rem'
                                    },
                                }}
                                sx={{ height: '2rem' }}
                            />
                        )}
                        onChange={(event, newValue) => {
                            handleSelectKey(newValue);
                        }}
                        sx={{
                            display: 'inline-block',
                            '& .MuiInputBase-input': {
                                padding: '0 10px',
                                height: '2rem',
                                boxSizing: 'border-box',

                            },
                            width: '18rem',
                            borderRadius: '1rem',
                            border: 'none',

                        }}
                        value={selectedKey}
                        noOptionsText={language.SelectALanguage || 'Please, select a language'}
                    />
                </div>

                {selectedKey && Object.keys(selectedKey).length > 0 &&
                    <div className="selects-container" style={{ marginTop: '2rem', gap: '1rem' }}>
                        <div style={{ width: '50%' }}>
                            <div className='input__label'>{language.EditText}</div>
                            <input
                                value={selectedKey.label}
                                onChange={event => setSelectedKey({ ...selectedKey, label: event.target.value })}
                                className="input-edit-key"
                            />
                        </div>

                        <button
                            onClick={handleSaveChanges}
                            className='general-button primary'
                            style={{ alignSelf: 'end' }}
                        >{language.Save}</button>
                    </div>
                }
            </div>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
            <Footer />
        </div>
    );
};

export default LanguagePage;