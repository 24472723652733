import React, { useState, useEffect, useMemo } from 'react';

import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

import { Box } from '@mui/material';

import deleteState from '../../api/Location/deleteState.js';
import getPermission from '../../auth/GetPermission.js';
import getStates from '../../api/Location/getStates.js';
import AddButton from '../../Common/AddButton.js'
import GeneralTable from '../../Hooks/Table/GeneralTable.js';

import './css/CountriesPage.css';

import Spinner from 'react-bootstrap/Spinner';
import StatesNewModal from './StatesNewModal.js';
import StatesEditModal from './StatesEditModal.js';
import DeleteModal from '../../Common/DeleteModal';
import ErrorCases from '../../Common/ErrorCases.js';
import Footer from '../../Common/Footer.js';

function States() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [elementsPerPage] = useState(100);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [state, setState] = useState([]);
    const [stateId, setStateId] = useState(0);
    const language = JSON.parse(localStorage.getItem('language'));
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingState, setDeletingState] = useState([]);
    const [deleteError, setDeleteError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData();
                setData(response);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, []);

    async function getData() {
        const { data, error } = await getStates(currentUser.data.currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    }

    const handleEditClick = (id) => {
        setStateId(id);
        const stateToEdit = data.find((state) => state.stateId === id);
        setState({ name: stateToEdit.stateName, countryId: stateToEdit.countryId, abbreviation: stateToEdit.stateAbbreviation, tenantsDB: currentUser.data.currentTenant });
        setShowEditModal(true);
    };

    async function handleDeleteClick(id) {
        try {
            setIsLoading(true);
            const stateToDelete = { id: id, tenantsDB: currentUser.data.currentTenant };
            const response = await deleteState(stateToDelete);
            if(response.error !== '') {
                const error = ErrorCases(response.error);
                setDeleteError(error);
                return;
            }
            const newData = await getData();
            setData(newData);
            setShowDeleteModal(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }

    }

    const deleteConfirmation = (state) => {
        setDeleteError('');
        setDeletingState(state);
        setShowDeleteModal(true);
    };

    const columns = useMemo(
        () => [
            {
                header: language.state,
                accessorKey: 'stateName',
            },
            {
                header: language.Abbreviation,
                accessorKey: 'stateAbbreviation',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: language.Country,
                accessorKey: 'countryName',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            }
        ],
        [],
    );

    const rowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
            <button
                className="btn"
                onClick={() => handleEditClick(row.original.stateId)}
                disabled={!getPermission(currentUser, "LocationStates", "updateAllow")}>
                <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
            </button>
            <button
                className="btn"
                onClick={() => deleteConfirmation(row.original)}
                disabled={(!getPermission(currentUser, "LocationStates", "deleteAllow"))}>
                <FontAwesomeIcon icon={faTrashCan} className='table__icon svg-icon' />
            </button>
        </Box>
    );

    return (
        <div className='main-container-app'>
            <h1 className='module__title'>{language.States}</h1>

            {
                getPermission(currentUser, "LocationStates", "readAllow") &&
                <>
                    <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions} />
                    <StatesNewModal
                        show={showNewModal}
                        onHide={() => setShowNewModal(false)}
                        getData={getData}
                        setData={setData}
                    />
                    <StatesEditModal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        editingState={state}
                        editingStateId={stateId}
                        getData={getData}
                        setData={setData}
                    />
                </>
            }
            {
                showDeleteModal &&
                <DeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    message={language.DeleteStateMessage + deletingState.stateName}
                    handleDelete={() => handleDeleteClick(deletingState.stateId)}
                    error={deleteError}
                />
            }

            {
                getPermission(currentUser, "LocationStates", "writeAllow") &&
                <div className='addbutton__container' onClick={() => setShowNewModal(true)}><AddButton /></div>
            }

            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
            <Footer/>
        </div>

    );
}

export default States;