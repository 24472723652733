import userControlTenants from "../../api/EMSuser/userControlTenants";

const currentTenantsControl = async (userAttributes) =>{
    const userSub = userAttributes.sub;
    let currentTenantsControl;
    if(userSub){
        const tenants = await userControlTenants({idCognito:userSub});

        currentTenantsControl = tenants[0].tenantsKey
        localStorage.setItem('currentUser', JSON.stringify({
            data:{
            currentTenant:currentTenantsControl
            }
        }));
    }

    return currentTenantsControl;
}

export default currentTenantsControl;