import { Button, Card, ListGroup, Spinner } from "react-bootstrap";
import Footer from "../../Common/Footer";
import { useEffect, useState } from "react";
import GetAllUsers from "../../api/EMSuser/getAllUsers";
import GetAllTenants from "../../api/Tenants/getAllTenants";
import AssignTenant from "../../api/Tenants/assignTenant";

function TenantsAssignment() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [error, setError] = useState(null);
    const language = JSON.parse(localStorage.getItem('language'));
    const [tenantsData, setTenantsData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedTenants, setSelectedTenants] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData();
                const tenantsResponse = await getTenantsData();
                setData(response);
                setTenantsData(tenantsResponse.tenants);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);

    async function getData() {
        const { data, error } = await GetAllUsers(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    async function getTenantsData() {
        const { data, error } = await GetAllTenants(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const handleUsersCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedUsers((prev) => [...prev, Number(value)]);
        } else {
            setSelectedUsers((prev) => prev.filter((id) => id !== Number(value)));
        }
    };

    const handleTenantsCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedTenants((prev) => [...prev, Number(value)]);
        } else {
            setSelectedTenants((prev) => prev.filter((id) => id !== Number(value)));
        }
    };

    const handleAssignTenants = async () => {
        
        const tenantsAssignmentList = selectedUsers.flatMap(user =>
            selectedTenants.map(tenant => ({
                idCognito: data.find(item => item.id === user).idCognito,
                idTenants: tenant
            }))
        );
        const body = {
            tenantsDB: currentTenant,
            tenantAssignmentList: tenantsAssignmentList
        };
        try {
            setIsLoading(true);
            const response = await AssignTenant(body);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="main-container-app">
            <h1 className='module__title'>{language.TenantsAssignment}</h1>
            <div className="main-box" >
            <div>{language.SelectUser}</div>
                <div className='inputs-row__no-justified email-assign__row'>
                    <div style={{ width: '45%' }}>
                        <Card style={{ height: '14rem', overflow: 'auto' }}>
                            <Card.Header className='card__label'>{language.Users}</Card.Header>
                            <ListGroup variant="flush">
                                {data.map((user, index) => {
                                    return (
                                        <ListGroup.Item className='card__item'
                                            key={user.id}>
                                            <input type="checkbox"
                                                value={user.id}
                                                key={user.id}
                                                onChange={handleUsersCheckboxChange}
                                                checked={selectedUsers.includes(user.id)}
                                                className='email-assign__checkbox'
                                            />
                                            {user.name}</ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        </Card>
                    </div>

                    <div style={{ width: '45%' }}>
                        <Card style={{ height: '14rem', overflow: 'auto' }}>
                            <Card.Header className='card__label'>{language.Tenants}</Card.Header>
                            <ListGroup variant="flush">
                                {tenantsData.map((tenant, index) => {
                                    return (
                                        <ListGroup.Item className='card__item'><input type="checkbox"
                                            value={tenant.idTenants}
                                            key={tenant.idTenants}
                                            onChange={handleTenantsCheckboxChange}
                                            checked={selectedTenants.includes(tenant.idTenants)}
                                            className='email-assign__checkbox'
                                        />
                                            {tenant.tenantsName}</ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        </Card>
                    </div>

                </div>
                <button onClick={handleAssignTenants}
                    className={`general-button ${selectedUsers.length === 0 || selectedTenants.length === 0 ? 'disabled' : 'primary'}`}
                    style={{ marginTop: '1rem' }}
                    disabled={selectedUsers.length === 0 || selectedTenants.length === 0}
                >{language.Assign}</button>
            </div>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
            <Footer />
        </div>
    )
}

export default TenantsAssignment;