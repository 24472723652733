import postMethod from "../../Hooks/API/post";

async function getLanguageFile(body) {
    const path = '/Languages/GetLanguageFile_v2';
    try {
        const res = await postMethod(body, path);
        return res;
    } catch (error) {
        return {error};
    }
}

export default getLanguageFile;