import GeneralTable from "../../../Hooks/Table/GeneralTable";

import { Box } from '@mui/material';

const formattedAmount = (amount) => {
    const number = parseFloat(amount);
    if (isNaN(number)) {
        return 'Invalid amount';
    }
    return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
} 

const formatDate = (isoDate,languageCode) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString(languageCode, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    });
};

const ReportsTable = ({data,groupData,reportId,dropDown,startDate,endDate}) =>{
    const language = JSON.parse(localStorage.getItem('language'));
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const languageCode = currentUser.data.userData.languageCode;
    const currency = currentUser.data.catalog.currency[0];
    let groupFilesString="";

    if(groupData){
        let parsedData = JSON.parse(groupData);
        let modifiedData = Object.keys(parsedData).reduce((acc, key) => {
            let newKey = key.replace(/_g$/, '');
            acc[newKey] = parsedData[key];
            return acc;
        }, {});
        groupFilesString = Object.keys(modifiedData).join(',');
        groupFilesString = [groupFilesString];
    }

    const cells = (key) => {
        switch (key) {
            case 'Coins':
            case 'Bills':
            case 'Credit':
            case 'QrIn':
            case 'QrOut':
            case 'Total':
            case 'Total':
            case 'ProductCost':
            case 'TotalPaymentProduct':
            case 'BillsCoins':
            case 'TotalProductCost':
            case 'Revenue':
                return ({ renderedCellValue }) => (
                    <Box>
                        {currency.sign + formattedAmount(renderedCellValue)} 
                    </Box>
                );
            case 'Date':
                return ({renderedCellValue}) => (
                    <Box>
                        {formatDate(renderedCellValue,languageCode)}
                    </Box>
                )
            default:
                return undefined;
        }
    };

    const muiTableBodyCellProps = (key) => {
        switch (key) {
            case 'Coins':
            case 'Bills':
            case 'Credit':
            case 'QrIn':
            case 'QrOut':
            case 'Total':
            case 'Total':
            case 'ProductCost':
            case 'TotalPaymentProduct':
            case 'BillsCoins':
            case 'TotalProductCost':
            case 'Revenue':
                return ({align: 'right'});
            case 'StationName':
            case 'NameTVM':
            case 'TVMNumber':
            case 'ProductName':
            case 'Status':
            case 'LocalId':
            case 'idTransaction':
            case 'ProductPrinter':
            case 'ProductQuantity':
                return ({align: 'left'});
            default:
                return undefined;
        }
    };

    const columns_table = [];
    if (data.length > 0) {
        Object.keys(data[0]).map(key => (columns_table.push({
            header: language[key],
            accessorKey: key,
            Cell:cells(key),
            muiTableBodyCellProps:muiTableBodyCellProps(key)
        })));
    }
    return (
        <GeneralTable
            data={data} 
            columns_table={columns_table} 
            actions={false}
            groupFiles={false}
            exportFile={true}
            reportId={reportId}
            dropDown={dropDown}
            startDate={startDate} 
            endDate={endDate}
        />
    );
}

export default ReportsTable;