import NavbarAvatar from './NavbarAvatar';
import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import { FaLocationDot } from "react-icons/fa6";
import { MdAttachMoney } from "react-icons/md";
import { MdDashboard } from "react-icons/md";
import { AiFillTool } from "react-icons/ai";
import { MdFactory } from "react-icons/md";
import { RiAlarmWarningFill } from "react-icons/ri";
import { GrTransaction } from "react-icons/gr";
import { MdCompare } from "react-icons/md";
import { FaTools } from "react-icons/fa";
import { MdLocationCity } from "react-icons/md";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaProductHunt } from "react-icons/fa";
import { FaMoneyBill } from "react-icons/fa";
import { TbCircleLetterT } from "react-icons/tb";
import { TbCircleLetterF } from "react-icons/tb";
import { FaGear } from "react-icons/fa6";
import { HiCommandLine } from "react-icons/hi2";
import { MdMarkEmailUnread } from "react-icons/md";
import { CiLogout } from "react-icons/ci";
import { AiOutlineCustomerService } from "react-icons/ai";

import languages from '../auth/LoginLanguage.json';
const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'EN';
let language = languages ? languages[currentLanguage][0] : [];



export const SidebarData = [
    {
        title: language.Dashboard,
        icon: <img src='./img/icons/menu/Dashboard.svg' className='menu__icon' />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        cName: 'nav-text',
        subNav: [
            {
                title: language.GeneralDashboard,
                path: '/general-dashboard',
                icon: <MdDashboard />,
                cName: 'nav-text',
                nameModule: 'GeneralDashboard'
            },
            {
                title: language.TVM,
                icon: <MdDashboard />,
                iconClosed: <RiIcons.RiArrowDownSFill />,
                iconOpened: <RiIcons.RiArrowUpSFill />,
                cName: 'nav-text',
                subNav: [
                    {
                        title: language.Alarms,
                        path: '/dashboard-alarms',
                        icon: <RiAlarmWarningFill />,
                        cName: 'nav-text',
                        nameModule: 'TVMDashboardAlarms'
                    },
                    {
                        title: language.Transactions,
                        path: '/dashboard-transactions',
                        icon: <GrTransaction className='transaction-icon' />,
                        cName: 'nav-text',
                        nameModule: 'TVMDashboardTransactions'
                    },
                    {
                        title: language.Compare,
                        path: '/dashboard-compare',
                        icon: <MdCompare />,
                        cName: 'nav-text',
                        nameModule: 'TVMDashboardCompare'
                    }
                ]
            }/*,
            {
                title: language.Farebox,
                icon: <MdDashboard />,
                iconClosed: <RiIcons.RiArrowDownSFill/>,
                iconOpened: <RiIcons.RiArrowUpSFill/>,
                cName: 'nav-text',
                subNav: [
                    {
                        title: language.Alarms,
                        path: '/farebox-alarms',
                        icon: <RiAlarmWarningFill />,
                        cName: 'nav-text',
                        nameModule: 'FXBDashboardAlarms'
                    },
                    {
                        title: language.Transactions,
                        path: '/farebox-transactions',
                        icon: <GrTransaction />,
                        cName: 'nav-text',
                        nameModule: 'FXBDashboardTransactions'
                    },
                    {
                        title: language.Compare,
                        path: '/farebox-compare',
                        icon: <MdCompare />,
                        cName: 'nav-text',
                        nameModule: 'FXBDashboardCompare'
                    }
                ]
            }*/
        ]
    },
    {
        title: language.UserManagement,
        icon: <img src='./img/icons/menu/User Management.svg' className='menu__icon' />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        cName: 'nav-text',
        subNav: [
            {
                title: language.Users,
                path: '/user-manager',
                icon: <FaIcons.FaUserEdit />,
                cName: 'nav-text',
                nameModule: 'UMUsers'
            },
            {
                title: language.Roles,
                path: '/roles',
                icon: <FaIcons.FaRegistered />,
                cName: 'nav-text',
                nameModule: 'UMRoles'
            },
            {
                title: language.Tenants,
                path: '/tenants',
                icon: <MdFactory />,
                cName: 'nav-text',
                nameModule: 'UMTenants'
            },
            // {
            //     title: language.Tenants,
            //     cName: 'nav-text',
            //     subNav: [
            //         {
            //             title: language.Tenants,
            //             path: '/tenants',
            //             icon: <MdFactory />,
            //             cName: 'nav-text',
            //             nameModule: 'UMTenants'
            //         },
            //         {
            //             title: 'Tenants Assignment',
            //             path: '/tenants-assignment',
            //             cName: 'nav-text',
            //             nameModule: 'UMRoles'
            //         }
            //     ]
            // }

        ]
    },
    {
        title: language.Reports,
        icon: <img src='./img/icons/menu/Reports.svg' className='menu__icon' />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        cName: 'nav-text',
        subNav: [
            {
                title: language.Changes,
                path: '/reports-changes',
                icon: <IoIcons.IoIosPaper />,
                cName: 'nav-text',
                nameModule: 'ReportsChanges'
            },
            {
                title: language.Transactions,
                path: '/reports-transactions',
                icon: <GrTransaction />,
                cName: 'nav-text',
                nameModule: 'ReportsTransactions'
            },
            {
                title: language.Alarms,
                path: '/reports-alarms',
                icon: <RiAlarmWarningFill />,
                cName: 'nav-text',
                nameModule: 'ReportsAlarms'
            },
            {
                title: language.Custom,
                path: '/reports-custom',
                icon: <FaTools />,
                cName: 'nav-text',
                nameModule: 'ReportsCustom'
            }
        ]
    },
    {
        title: language.Location,
        icon: <img src='./img/icons/menu/Location.svg' className='menu__icon' />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        cName: 'nav-text',
        subNav: [
            {
                title: language.Countries,
                path: '/location-countries',
                icon: <FaLocationDot />,
                cName: 'nav-text',
                nameModule: 'LocationCountries'
            },
            {
                title: language.States,
                path: '/location-states',
                icon: <FaLocationDot />,
                cName: 'nav-text',
                nameModule: 'LocationStates'
            },
            {
                title: language.Cities,
                path: '/location-cities',
                icon: <FaLocationDot />,
                cName: 'nav-text',
                nameModule: 'LocationCities'
            },
            {
                title: language.Stations,
                path: '/location-stations',
                icon: <FaLocationDot />,
                cName: 'nav-text',
                nameModule: 'LocationStation'
            }
        ]
    },
    {
        title: language.Fare,
        icon: <img src='./img/icons/menu/Fares.svg' className='menu__icon' />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        cName: 'nav-text',
        subNav: [
            {
                title: language.Products,
                path: '/fare-products',
                icon: <FaProductHunt />,
                cName: 'nav-text',
                nameModule: 'FareProducts'
            },
            {
                title: language.Currency,
                path: '/fare-currency',
                icon: <FaMoneyBill />,
                cName: 'nav-text',
                nameModule: 'FareCurrency'
            }
        ]
    },
    {
        title: language.DeviceManager,
        icon: <img src='./img/icons/menu/Device Manager.svg' className='menu__icon' />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        cName: 'nav-text',
        subNav: [
            {
                title: language.TVM,
                icon: <TbCircleLetterT />,
                iconClosed: <RiIcons.RiArrowDownSFill />,
                iconOpened: <RiIcons.RiArrowUpSFill />,
                cName: 'nav-text',
                subNav: [
                    {
                        title: language.TVM,
                        path: '/dmtvm',
                        icon: <TbCircleLetterT />,
                        cName: 'nav-text',
                        nameModule: 'DMTVM'
                    },
                    {
                        title: language.CustomAlarms,
                        path: '/dmtvm-custom-alarms',
                        icon: <RiAlarmWarningFill />,
                        cName: 'nav-text',
                        nameModule: 'DMTVMCustomAlarms'
                    },
                    {
                        title: language.GlobalConfig,
                        path: '/dmtvm-global-config',
                        icon: <AiFillTool />,
                        cName: 'nav-text',
                        nameModule: 'GLobalConfigTVM'
                    },
                    {
                        title: language.FullStatusControl,
                        path: '/full-status-control',
                        icon: <AiFillTool />,
                        cName: 'nav-text',
                        nameModule: 'TVMFullStatusControl'
                    }
                ]
            }/*,
            {
                title: language.Farebox,
                icon: <TbCircleLetterF />,
                iconClosed: <RiIcons.RiArrowDownSFill/>,
                iconOpened: <RiIcons.RiArrowUpSFill/>,
                cName: 'nav-text',
                subNav: [
                    {
                        title: language.Farebox,
                        path: '/dm-farebox',
                        icon: <TbCircleLetterF />,
                        cName: 'nav-text',
                        nameModule: 'DMFarebox'
                    },
                    {
                        title: language.CustomAlarms,
                        path: '/dm-farebox-custom-alarms',
                        icon: <RiAlarmWarningFill />,
                        cName: 'nav-text',
                        nameModule: 'DMFareboxCustomAlarms'
                    }
                ]
            }*/,
            {
                title: language.Configuration,
                icon: <FaGear />,
                iconClosed: <RiIcons.RiArrowDownSFill />,
                iconOpened: <RiIcons.RiArrowUpSFill />,
                cName: 'nav-text',
                subNav: [
                    {
                        title: language.Commands,
                        path: '/dm-config-commands',
                        icon: <HiCommandLine />,
                        cName: 'nav-text',
                        nameModule: 'DMConfigCommands'
                    },
                    {
                        title: language.Denominations,
                        path: '/dm-config-denominations',
                        icon: <MdAttachMoney />,
                        cName: 'nav-text',
                        nameModule: 'DMConfigDenominations'
                    },
                    {
                        title: language.DevicesAlarms,
                        path: '/dm-config-devices-alarms',
                        icon: <RiAlarmWarningFill />,
                        cName: 'nav-text',
                        nameModule: 'DMConfigDevicesAlarms'
                    },
                    {
                        title: language.EmailAlarms,
                        path: '/dm-config-email-alarms',
                        icon: <MdMarkEmailUnread />,
                        cName: 'nav-text',
                        nameModule: 'DMConfigEmailAlarms'
                    },
                    {
                        title: language.Reports,
                        path: '/dm-config-reports',
                        icon: <IoIcons.IoIosPaper />,
                        cName: 'nav-text',
                        nameModule: 'DMConfigReports'
                    },
                    {
                        title: language.Language,
                        iconClosed: <RiIcons.RiArrowDownSFill />,
                        iconOpened: <RiIcons.RiArrowUpSFill />,
                        cName: 'nav-text',
                        subNav: [
                            {
                                title: language.EMS || 'EMS',
                                path: '/dm-config-language',
                                cName: 'nav-text',
                                nameModule: 'LanguagesFileEMS'
                            },
                            {
                                title: language.TVM,
                                path: '/dm-config-language-tvm',
                                cName: 'nav-text',
                                nameModule: 'LanguagesFileTVM'
                            },
                        ],
                    }
                ]
            }
        ]
    },
    {
        title: language.CustomerService,
        icon: <AiOutlineCustomerService style={{ color: '#5b5b5b', fontSize: '1.5rem' }} />,
        cName: 'nav-text',
        subNav: [
            {
                title: language.ChangeTicket,
                path: '/change-ticket',
                icon: <MdDashboard />,
                cName: 'nav-text',
                nameModule: 'CSChangeQR'
            },
        ]
    },
    {
        title: language.SignOut,
        // icon: <CiLogout style={{  color: '#5b5b5b', fontSize: '1.5rem' }} />,
        icon: <img src='./img/no-user.jpg' className='navbar__avatar' />,
        cName: 'nav-text',
        nameModule: 'SignOut'
    }
];