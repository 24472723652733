import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import {
  signIn,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  updateMFAPreference,
  signOut
} from 'aws-amplify/auth';

import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';

import { BsPerson } from "react-icons/bs";
import { BsLock } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import { Spinner } from "react-bootstrap";

import "./css/Auth.css";

import SendEmail from '../api/EMSuser/sendEmail';
import GetUser from '../api/EMSuser/getUser';
import getLanguageFile from '../api/Languages/getLanguageFile';
import CustomAlert from '../Common/CustomAlert';
import languages from './LoginLanguage.json';

import CurrentLanguageFile from '../Hooks/language/CurrentLanguageFile';
import userControl
 from '../api/EMSuser/userControlTenants';
import currentTenantsControl from '../Hooks/currentTenant/currentTenantsControl';


function LoginPage() {
  const navigate = useNavigate()
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // localStorage.clear();
  localStorage.setItem('currentLanguage', "EN");
  const currentLanguage = localStorage.getItem('currentLanguage');
  const language = languages[currentLanguage][0];
  const [tokenExpired, setTokenExpired] = useState(false);

  useEffect(() => {
    const currentLocalUser = JSON.parse(localStorage.getItem('currentUser'));

    ((authenticated && currentLocalUser.data.tenantsUser.length === 1) || (authenticated && currentLocalUser.data.currentTenant !== '' && currentLocalUser.data.tenantsUser.length > 1))
      && navigate('/Home');

    (authenticated && currentLocalUser.data.currentTenant === '' && currentLocalUser.data.tenantsUser.length > 1)
      && navigate('/select-tenant');


    checkAuthentication();

    const tokenExpiration = JSON.parse(localStorage.getItem('tokenExpired'));
    if (tokenExpiration) {
      setTokenExpired(true)
    }
  }, [authenticated]);

  const checkAuthentication = async () => {
    try {
      const currentUser = await getCurrentUser();
      setAuthenticated(!!currentUser);
    } catch (error) {
      setAuthenticated(false);
    }
  };

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      localStorage.clear();
      const { isSignUpComplete, userId, isSignedIn, nextStep } = await signIn({ username, password })

      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        navigate('/new-password');
      }
      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE') {
        navigate('/sms-code');
      }

      const userAttributes = await fetchUserAttributes();
      await currentTenantsControl(userAttributes);
      
      if (nextStep.signInStep === 'DONE' && userAttributes.email_verified === 'true') {
        if (userAttributes.phone_number_verified !== 'true') {
          await updateMFAPreference({ sms: 'PREFERRED' });
        }

        const currentUser = await GetUser();

        await CurrentLanguageFile(currentUser);
        localStorage.setItem('currentLanguage', currentUser.data.userData.languageAbbreviation);

        if (currentUser.data.tenantsUser.length === 1) {
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          navigate('/Home');
          navigate(0);
        } else {
          currentUser.data.currentTenant = '';
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          navigate('/select-tenant');
        }
      } else if (nextStep.signInStep === 'DONE') {
        const response = await SendEmail();
        localStorage.setItem('confirmEmail', JSON.stringify(true));
        navigate('/email-code');
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.message)
      if (error.toString().includes('There is already a signed in user')) {
        await signOut();
      } else {
        setShowAlert(true);
      }

    }
    setIsLoading(false);
  }

  const togglePassword = () => {
    var passwordInput = document.getElementById("password");

    if (passwordInput.type === "password") {
      passwordInput.type = "text";
      setIsPasswordHidden(false);
    } else {
      passwordInput.type = "password";
      setIsPasswordHidden(true);
    }
  }

  const handleOK = () => {
    localStorage.removeItem('tokenExpired');
    localStorage.removeItem('logout');
    setTokenExpired(false);
  };

  return (
    <>
      <div className="main-container">
        <img
          loading="lazy"
          src="./img/logoBEATT.png"
          className="login__logo"
        />
        <div className="login__container">
          <div className='login__inputs-group'>
            <div className="login__input">
              <img src='./img/icons/login/Username.png' className='login__icon' />
              <input className="login__form" type="text" placeholder={language.UserName}
                onChange={evt => setUserName(evt.target.value)} />
            </div>
            <div className="login__input">
              <img src='./img/icons/login/Password.png' className='login__icon' />
              <input className="login__form" id="password" type="password" minLength="8" placeholder={language.Password}
                onChange={evt => setPassword(evt.target.value)}
                autoComplete="off" />
              {
                isPasswordHidden ? (
                  <img src='./img/icons/login/Disguise-01.png' className='login__icon-right'
                    onClick={togglePassword} />
                ) : (
                  <BsEye className='login__icon-right' onClick={togglePassword} />
                )
              }
            </div>
          </div>

          <div className="button-light-blue" onClick={handleLogin}>{language.LOGIN}</div>
        </div>
        <div className="login__bottom">
          <div className="login__bottom-remember">
            {/* <Form.Check
                        type='checkbox'
                        id='rememberCheckbox'
                    />
                    <div className="login__bottom-remember-text">{language.RememberMe}</div> */}
          </div>
          <Link to="/forgot-password"><div className="login__bottom-forgot">{language.ForgotPassword}</div></Link>
        </div>
        {
          showAlert &&
          <CustomAlert message={language.IncorrectUsernameAlert} />
        }
      </div>
      {isLoading &&
        <div className='spinner-container'>
          <Spinner animation="border" variant="primary" className='spinner' />
        </div>
      }
      {tokenExpired && <Modal show={tokenExpired} centered>
        <ModalHeader>
          <ModalTitle>{language.SessionExpired}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {language.YourSessionHasExpired}
        </ModalBody>
        <ModalFooter>
          <Button className='general-button primary' onClick={handleOK}>
            {language.Ok}
          </Button>
        </ModalFooter>
      </Modal>}
    </>
  );
}

export default LoginPage;