import { signOut } from 'aws-amplify/auth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';



function ProfileOverlay({ show, onClose, position, setShowModal, handleResetPassword, 
    handleChangeEmail, handleChangePhone, username, handleDeleteClick, handleProfileModal }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const language = JSON.parse(localStorage.getItem('language'));
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            localStorage.removeItem('openSession');
            const response = await signOut();
            navigate(0);
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {show && (<>

                <div className="overlay" onClick={onClose} style={{
                    position: 'fixed',
                    left: position.x,
                    top: position.y
                }}>
                    <button className="close-button" onClick={onClose}>X</button>
                    <div className='overlay__link separated' onClick={handleProfileModal}>{language.Profile}</div>
                    <div className='overlay__link-red' onClick={handleLogout}>{language.SignOut}</div>
                </div>

                
            </>)}
            
        </>
    );
}

export default ProfileOverlay;
