import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

// #region components 
import CustomReportsTable from "./tables/CustomReportsTable";
import getAllCustomReports from "../../api/CustomReports/getAllCustomReports";
import getPermission from '../../auth/GetPermission.js';
import CustomReportsEditModal from "./Modals/CustomReportsEditModal";
import CustomReportsNewModal from "./Modals/CustomReportsNewModal";
import getCustomReportById from "../../api/CustomReports/getCustomReportById";
import AddButton from "../../Common/AddButton";
import deleteCustomReportById from "../../api/CustomReports/deleteCustomReportById.js";
import DeleteModal from "../../Common/DeleteModal.js";
import ErrorCases from "../../Common/ErrorCases.js";
import Footer from "../../Common/Footer.js";
// #endregion

const CustomReportsPage = () => {
    // #region get the language
    const language = JSON.parse(localStorage.getItem('language'));
    // #endregion

    // #region get the tenant 
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    // #endregion

    // #region variables stored in state hooks
    const [data, setData] = useState([]);//receiving general data for the table
    const [error, setError] = useState('');//error or warning message when an incorrect action is done display to the user
    const [isLoading, setIsLoading] = useState(false);//render a load in case of data waits
    const [reportEdit, setReportEdit] = useState({});//modal data to edit
    const [showNewModal, setShowNewModal] = useState(false);//Modal to create data
    const [showEditModal, setShowEditModal] = useState(false);//Modal to edit data
    const [changeData, setChangeData] = useState(false);//If there are data changes, re-render
    const [showDeleteModal, setShowDeleteModal] = useState(false);//shows delete modal
    const [deletingReport, setDeletingReport] = useState([]);//report to delete
    const [deleteError, setDeleteError] = useState('');//deleting error
    // #endregion

    // #region makes all the effects of the hook, each editData change will re-render
    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const { data } = await getAllCustomReports({ tenantsDB: currentTenant });
                setData(data);
                setIsLoading(false);
            } catch (error) {
                handleError(error.message);
            }
        }

        fetchData();
    }, [currentTenant, changeData]);
    // #endregion

    // #region Events handle
    async function handleDeleteClick(id) {
        setIsLoading(true);
        try {
            const response = await deleteCustomReportById({ id, tenantsDB: currentTenant });
            if(response.error !== undefined) {
                const error = ErrorCases(response.error);
                setDeleteError(error);
                return;
            }
            setChangeData(!changeData);
            setShowDeleteModal(false);
        } catch (error) {
            handleError(error.message);
            console.log(error.message)
        } finally {
            setIsLoading(false);
        }
    }

    const handleError = (errorMessage) => {
        setError(errorMessage);
        setTimeout(() => {
            setError('');
        }, 10000);
    };

    const handleEditClick = async (reportId) => {
        setIsLoading(true);
        try {
            let { data } = await getCustomReportById({ tenantsDB: currentTenant, id: reportId });
            setReportEdit(data[0]);
            setShowEditModal(true);
        } catch (error) {
            handleError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteConfirmation = (report) => {
        setDeletingReport(report);
        setDeleteError('');
        setShowDeleteModal(true);
    };
    // #endregion

    // #region generate icons for the table
    const rowActions = ({ row }) => (
        <Box >
            <button
                className="btn"
                onClick={() => handleEditClick(row.original.idCustomReports)}
                disabled={!getPermission(currentUser, "ReportsCustom", "updateAllow")}>
                <img src='./img/icons/pages/EditUser-01.png' className='table__icon' />
            </button>
            <button
                className="btn"
                onClick={() => deleteConfirmation(row.original)}
                disabled={(!getPermission(currentUser, "ReportsCustom", "deleteAllow"))}>
                <FontAwesomeIcon icon={faTrashCan} className='table__icon svg-icon' />
            </button>
        </Box>
    );
    // #endregion

    // #region components
    const LoadingSpinner = ({ isLoading }) => (
        isLoading && (
            <div className='spinner-container'>
                <Spinner animation="border" variant="primary" className='spinner' />
            </div>
        )
    );

    const ErrorAlert = ({ error }) => (
        error && <div className="alert alert-danger">{error}</div>
    )
    // #endregion
    return (
        <div className='main-container-app'>
            <ErrorAlert error={error} />
            <h1 className='module__title'>Custom reports</h1>
            {getPermission(currentUser, "ReportsCustom", "readAllow") && (
                <>
                    { /* table */}
                    {data.length > 0 && <CustomReportsTable data={data} rowActions={rowActions} language={language} />}
                    {/* add button */}
                    {
                        getPermission(currentUser, "LocationCountries", "writeAllow") && (
                            <div className='addbutton__container' onClick={() => setShowNewModal(true)}>
                                <AddButton />
                            </div>
                        )
                    }
                    {/* Process to generate information loading effect */}
                    <LoadingSpinner isLoading={isLoading} />
                    {/* Modal for edit custom report */}
                    <CustomReportsEditModal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        getData={reportEdit}
                        updateData={setChangeData}
                        flagUpdateData={changeData}
                        setIsLoading={setIsLoading}
                        idData={reportEdit.idCustomReports}
                    />
                    {/* Modal for create custom report */}
                    <CustomReportsNewModal
                        show={showNewModal}
                        onHide={() => setShowNewModal(false)}
                        updateData={setChangeData}
                        flagUpdateData={changeData}
                        setIsLoading={setIsLoading}
                    />
                    {/* Modal for delete custom report */}
                    {
                        showDeleteModal &&
                        <DeleteModal
                            show={showDeleteModal}
                            onHide={() => setShowDeleteModal(false)}
                            message={language.DeleteCustomReportMessage + deletingReport.name}
                            handleDelete={() => handleDeleteClick(deletingReport.idCustomReports)}
                            error={deleteError}
                        />
                    }
                </>
            )}
            <Footer/>
        </div>
    );
};

export default CustomReportsPage;