import { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";

import setFullStatusControl from '../../../api/TVM/setFullStatusControl';
import ErrorCases from '../../../Common/ErrorCases';

function FullStatusNewModal({ show, onHide, setIsLoading, getData, setData }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [status, setStatus] = useState({
        active: 1, password: '', accessType: '', tenantsDB: currentTenant,
        name: ''
    });
    const language = JSON.parse(localStorage.getItem('language'));
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [accessTypeError, setAccessTypeError] = useState('');
    const [createError, setCreateError] = useState('');

    const handlePasswordChange = (evt) => {
        const newValue = evt.target.value;
        if (/^\d*$/.test(newValue)) {
            setStatus({ ...status, password: newValue })
        }
    };

    const handleSave = async () => {
        
        const isDataValid = validateData();
        if (isDataValid) {
            try {
                setIsLoading(true);
                const response = await setFullStatusControl(status);
                if (response.error !== undefined) {
                    const error = ErrorCases(response.error);
                    setCreateError(error);
                    return;
                }
                const newData = await getData();
                setData(newData);
                onHide()
            } catch (error) {
                console.error(error);
                setCreateError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const validateData = () => {
        const isAccessTypeValid = validateAccessType();
        const isPasswordLengthValid = validatePasswordLenght();

        return isAccessTypeValid && isPasswordLengthValid;
    };

    const validateAccessType = () => {
        if (status.accessType !== '') {
            setAccessTypeError('');
            return true;
        } else {
            setAccessTypeError(language.AccessTypeError);
            return false;
        }
    };

    const validatePasswordLenght = () => {
        if (status.password.length >= 8) {
            setPasswordError('');
            return true;
        } else {
            setPasswordError(language.PasswordError);
            return false;
        }
    };

    const handleCancel = () => {
        onHide();
        clearData();
    };

    const clearData = () => {
        setStatus({
            active: 1, password: '', accessType: '', tenantsDB: currentTenant,
            name: ''
        });
        setCreateError('');
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header className='modal-header'>
                <Modal.Title >{language.NewFullStatusControl}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='inputs-row__no-justified'>
                    <div className=''>
                        <label className='input__label'>{language.AccessType}</label> <br />
                        <select onChange={evt => setStatus({ ...status, accessType: evt.target.value })}
                            className='module__input-search'>
                            <option value={''}>{language.SelectAnAccessType}</option>
                            <option value={'Revenue'}>{language.Revenue}</option>
                            <option value={'Maintenance'}>{language.Maintenance}</option>
                            <option value={'Administrator'}>{language.Administrator}</option>
                        </select>
                    </div>
                    <div className='active-input'>
                        <label className='input__label'>{language.Active}</label> <br />
                        <img src={status.active === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                            className='table__checkbox'
                            onClick={() => status.active === 1 ? setStatus({ ...status, active: 0 }) : setStatus({ ...status, active: 1 })} />
                    </div>
                </div>
                {accessTypeError && (
                    <div className='alert-message'>
                        {accessTypeError}
                    </div>
                )}

                <div className='input-name'>
                    <label className='input__label'>{language.Name}</label> <br />
                    <input placeholder={language.NamePlaceholder}
                        className='module__input-search'
                        onChange={evt => setStatus({ ...status, name: evt.target.value })}
                    ></input>
                </div>

                <div>
                    <label className='input__label'>{language.Password}</label> <br />
                    <input
                        className='module__input-search'
                        onChange={handlePasswordChange}
                        value={status.password}
                        placeholder={language.NumericPassword}
                    />
                </div>
                {passwordError && (
                    <div className='alert-message'>
                        {passwordError}
                    </div>
                )}
                <div className='error-alert'>{createError}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='general-button danger' onClick={handleCancel}>
                    {language.Cancel}
                </Button>
                <Button className='general-button primary' onClick={handleSave}>
                    {language.Save}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FullStatusNewModal;