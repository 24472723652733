import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { signOut, getCurrentUser } from 'aws-amplify/auth';
import resetPassword from '../api/EMSuser/resetPassword';
import GetPermission from '../auth/GetPermission';

import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { IconContext } from 'react-icons';
import { RiAlarmWarningFill } from "react-icons/ri";
import Alert from 'react-bootstrap/Alert';
import * as RiIcons from 'react-icons/ri';

import { SidebarData } from './SideBarData';
//import SubMenu from './SubMenu';
import './css/NavBar.css';
import ProfileOverlay from './ProfileOverlay';
import CreateUserModal from '../page/UserManager/CreateUser';

function Navbar({ showSubn, setShowSubn }) {
    const [authenticated, setAuthenticated] = useState(false);
    const [sidebar, setSidebar] = useState(true);
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();
    const [alertMessage, setAlertMessage] = useState(false);
    const [alertVariant, setAlertVariant] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [showItem, setShowItem] = useState([]);
    const [showIndex, setShowIndex] = useState('');
    const [subnav, setSubnav] = useState([]);
    const [expanded, setExpanded] = useState({});
    //const [showSubn, setShowSubn] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser ? currentUser.data.currentTenant : '';
    const language = JSON.parse(localStorage.getItem('language'));
    const [showProfileOverlay, setShowProfileOverlay] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [editedUserData, setEditedUserData] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [showCreateUserModal, setShowCreateUserModal] = useState(false);


    const showSubnav = (item, index) => {
        if (deepEqual(item.subNav, subnav)) {
            setShowSubn(!showSubn);
            //toggleNavbar();
        } else {
            setShowSubn(true);
            setSubnav(item.subNav);
            setExpanded({ ...expanded, [index]: !expanded[index] });
            //toggleNavbar();
        }
    };

    const handleOutsideClick = (e) => {
        const navbar = document.querySelector('.nav-menu');
        const submenu = document.querySelector('.sub-menu');
        if (navbar && !navbar.contains(e.target) && submenu && !submenu.contains(e.target)) {
            setShowSubn(false);
            //toggleNavbar();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    function isObject(obj) {
        return obj !== null && typeof obj === 'object';
    }

    function deepEqual(obj1, obj2) {
        if (obj1 === obj2) {
            return true;
        }

        if (!isObject(obj1) || !isObject(obj2)) {
            return false;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            if (!keys2.includes(key)) {
                return false;
            }

            if (!deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    }



    const toggleSidebar = () => {
        setSidebar(!sidebar);
    };

    useEffect(() => {
        checkAuthentication();
    }, []);

    const checkAuthentication = async () => {
        try {
            const currentUser = await getCurrentUser();
            setAuthenticated(!!currentUser);
        } catch (error) {
            setAuthenticated(false);
        }
    };

    if (!authenticated || !currentUser || !currentUser.data?.currentTenant || !language) {
        return null;
    }

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleProfileClick = (event) => {
        const { clientX, clientY } = event;
        setPosition({ x: clientX, y: clientY });
        setShowProfileOverlay(true);
    };

    async function handleResetPassword(username) {
        try {
            const userData = {
                username: username,
                tenantsDB: currentTenant
            }
            const output = await resetPassword(userData);
            if (output.error !== "" && output.error.includes("Resend not possible")) {
                setAlertMessage("The password can't be resend to user: " + username + " because the email has been confirmed already");
                setAlertVariant('danger');
                setShowAlert(true);
            }
            if (output.error !== "" && output.error.includes("User does not exist")) {
                setAlertMessage("The user: " + username + " doesn't exist");
                setAlertVariant('danger');
                setShowAlert(true);
            }
            if (output.data.responseCognito.User.UserStatus && output.data.responseCognito.User.UserStatus === 'FORCE_CHANGE_PASSWORD') {
                setAlertMessage('The password has been resend to user: ' + username);
                setAlertVariant('primary');
                setShowAlert(true);
            }
        } catch (error) {
            //console.log({error});
        }
    }

    const handleProfileModal = () => {
        const userToEdit = currentUser.data.userData;
        setEditedUserData(userToEdit);
        setIsEditing(true);
        setShowCreateUserModal(true);
    };

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }} >

                <div className="nav-menu active">
                    <ul className="nav-menu-items">
                        {SidebarData.map((item, index) => (
                            <NavItem
                                item={item}
                                index={index}
                                key={index}
                                showSubnav={showSubnav}
                                expanded={expanded[index]}
                                showProfileOverlay={showProfileOverlay}
                                setShowProfileOverlay={setShowProfileOverlay}
                                handleProfileClick={handleProfileClick}
                            />
                        ))}
                    </ul>
                </div>

                <div className={`sub-menu ${showSubn ? 'show' : ''}`}>
                    {subnav.map((item, index) => {
                        const isAllowed = GetPermission(currentUser, item.nameModule, "readAllow");
                        return (isAllowed || !item.nameModule) ? (
                            <SubMenu item={item} key={index} />
                        ) : null;
                    })}
                </div>

            </IconContext.Provider>

            {showProfileOverlay &&
                <ProfileOverlay
                    show={true}
                    onClose={() => setShowProfileOverlay(false)}
                    position={position}
                    handleProfileModal={handleProfileModal}
                />
            }

            <CreateUserModal
                show={showCreateUserModal}
                onHide={() => setShowCreateUserModal(false)}
                editedUserData={editedUserData}
                editing={isEditing}
            />
        </>
    );
}

function NavItem({ item, index, showSubnav, expanded, handleLogout, showProfileOverlay, setShowProfileOverlay, handleProfileClick }) {
    return (
        <li className="nav-text">
            <Link to={item.path ? item.path : '#'}
                onClick={item.nameModule === 'SignOut' ? (event) => handleProfileClick(event) : () => showSubnav(item, index)}>
                <div>{item.icon}</div>
                <div>{item.nameModule === 'SignOut' ? '' : item.title}</div>
                {/* {item.subNav && expanded ? item.iconOpened : item.subNav ? item.iconClosed : null} */}
            </Link>
        </li>
    );
}

// function SubMenu({ item }) {
//     const [expanded, setExpanded] = useState(false);
//     const currentUser = JSON.parse(localStorage.getItem('currentUser'));

//     const toggleExpand = () => {
//         setExpanded(!expanded);
//     };

//     return (
//         <div>
//             <Link to={item.path} className='nav-subtitle' onClick={toggleExpand}>
//                 <div className='submenu__icon'>{item.subNav && (expanded ? <RiIcons.RiArrowUpSFill style={{ color: '#5b5b5b' }} />
//                     : <RiIcons.RiArrowDownSFill style={{ color: '#5b5b5b' }} />)}</div>
//                 <span>{item.title}</span>
//             </Link>
//             {
//                  expanded && item.subNav &&
//                 item.subNav.map((subItem, index) => {
//                     const isAllowed = GetPermission(currentUser, subItem.nameModule, "readAllow");

//                     return (isAllowed) ? (
//                     <Link to={subItem.path} key={index} className="nav-subtext">
//                         {/* {subItem.icon} */}
//                         <span>{subItem.title}</span>
//                     </Link>
//                     ) : null;
//                 }
//             )
//             }

//         </div>
//     );
// }

function SubMenu({ item }) {
    const [expanded, setExpanded] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <div>
            <Link to={item.path} className="nav-subtitle" onClick={toggleExpand}>
                <div className="submenu__icon">
                    {item.subNav &&
                        (expanded ? (
                            <RiIcons.RiArrowUpSFill style={{ color: '#5b5b5b' }} />
                        ) : (
                            <RiIcons.RiArrowDownSFill style={{ color: '#5b5b5b' }} />
                        ))}
                </div>
                <span>{item.title}</span>
            </Link>

            {expanded &&
                item.subNav &&
                item.subNav.map((subItem, index) => {
                    const isAllowed = GetPermission(currentUser, subItem.nameModule, "readAllow");

                    return isAllowed || subItem.subNav ? (
                        <div key={index} className="nav-submenu">
                            {subItem.subNav ? (
                                <div style={{marginLeft: '1rem'}}><SubMenu item={subItem}/></div>
                            ) : (
                                <Link to={subItem.path} className="nav-subtext">
                                    <span>{subItem.title}</span>
                                </Link>
                            )}
                        </div>
                    ) : null;
                })}
        </div>
    );
}


export default Navbar;