import { fetchAuthSession  } from '@aws-amplify/auth';
import { get } from 'aws-amplify/api';
import GetMethod from '../../Hooks/API/GetMethod';

async function GetUser(tenantsDB, id) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = tenantsDB?tenantsDB:currentUser.data.currentTenant;
    const userId = id ? id : 0;
    const path = `/Users/${userId}?tenantsDB=${currentTenant}`;
    try {
        const { data, error } = await GetMethod(path);
        return { data, error };
    } catch (error) {
        return {error};
    }
}
export default GetUser;
