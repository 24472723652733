import "./css/Footer.css";

function Footer() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const backendVersion = currentUser.data.catalog.version;
    return (
        <div className="version-footer">v 1.0.0.2/WS-{backendVersion}</div>
    );
}

export default Footer;