import React, { useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./SortableItem.css";
import Card from "../Card/CardAlarm";
import CardFinancial from "../Card/CardFinancial";
import CardCompare from "../Card/CardCompare";
import CardCompareAlarm from "../Card/CardCompareAlarm";
import CardInformativeAlarm from "../Card/CardInformativeAlarm";

const SortableItem = ({ item, id, handleCardDetails, cardType, languageCode, currency, language, onDraggingChange, isDraggable, programFromDate, programToDate,
    compareFromDate, compareToDate, setIsLoading, showModal, setShowModal }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging: isItemDragging
    } = useSortable({ id });

    const itemStyle = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isItemDragging ? 9999 : 'auto'
    };

    useEffect(() => {
        onDraggingChange(isItemDragging);
    }, [isItemDragging, onDraggingChange]);

    useEffect(() => {
        if (isItemDragging) {
            window.addEventListener('touchmove', handleTouchMove, { passive: false });
        } else {
            window.removeEventListener('touchmove', handleTouchMove);
        }

        return () => {
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [isItemDragging]);

    const handleTouchMove = (event) => {
        if (isItemDragging) {
            event.preventDefault();
        }
    };

    return (
        <div
            style={itemStyle}
            ref={setNodeRef}
            {...attributes}
            {...(isDraggable ? listeners : {})}>
            {
                cardType === 'Alerts' ? (
                    <Card
                        item={item}
                        name={item.name}
                        badge={item.totalRegister}
                        color={item.color}
                        typeStatus={item.statusType}
                        idDevice={item.idDevice}
                        handleCardDetails={handleCardDetails}
                        language={language}
                        isDragging={isItemDragging}
                        isDraggable={isDraggable} />
                ) : cardType === 'InformativeAlerts' ? (
                    <CardInformativeAlarm
                        item={item}
                        name={item.name}
                        badge={item.totalRegister}
                        color={item.color}
                        typeStatus={item.statusType}
                        idDevice={item.idDevice}
                        handleCardDetails={handleCardDetails}
                        language={language}
                        isDragging={isItemDragging}
                        isDraggable={isDraggable}
                    />
                ) : cardType === 'Compare' ? (
                    <CardCompare
                        item={item}
                        name={item.name}
                        typeIcon={item.typeIcon}
                        programTotal={item.programTotal}
                        compareTotal={item.compareTotal}
                        id={item.id}
                        languageCode={languageCode}
                        currency={currency}
                        language={language}
                        isDragging={isItemDragging}
                        isDraggable={isDraggable}
                        programFromDate={programFromDate}
                        programToDate={programToDate}
                        compareFromDate={compareFromDate}
                        compareToDate={compareToDate}
                    />
                ) : cardType === 'CompareAlarm' ? (
                    <CardCompareAlarm
                        item={item}
                        name={item.name}
                        badge={item.totalRegister}
                        color={item.color}
                        typeStatus={item.alarmType}
                        idDevice={item.idDevice}
                        handleCardDetails={handleCardDetails}
                        language={language}
                        isDragging={isItemDragging}
                        isDraggable={isDraggable}
                        programFromDate={programFromDate}
                        programToDate={programToDate}
                        compareFromDate={compareFromDate}
                        compareToDate={compareToDate}
                        setIsLoading={setIsLoading}
                        showModal={showModal}
                        setShowModal={setShowModal}
                    />
                ) : (
                    <CardFinancial
                        item={item}
                        name={item.name}
                        typeIcon={item.typeIcon}
                        total={item.total}
                        id={item.id}
                        languageCode={languageCode}
                        currency={currency}
                        language={language}
                        isDragging={isItemDragging}
                        isDraggable={isDraggable} />
                )
            }
        </div>
    );
};

export default SortableItem;
