import Modal from 'react-bootstrap/Modal';
import { Button, Form, Tab, Tabs } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { useState, useEffect } from 'react';

import getStations from '../../../api/Location/getStations';
import getLabelsTableById from '../../../api/TVM/getLabelsTableById';
import updateTVM from '../../../api/TVM/updateTVM';
import { Ports } from './Ports';

import CurrencyInput from 'react-currency-input-field';
import getGlobalConfig from '../../../api/TVM/getGlobalConfig';
import ErrorCases from '../../../Common/ErrorCases';

function TVMEditModal({ show, onHide, getData, setData, setIsLoading, editingTVM }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const languageCode = currentUser.data.userData.languageCode;
    const currency = currentUser.data.catalog.currency[0];
    const [userTVM, setUserTVM] = useState({
        username: editingTVM.userName,
        TVMName: editingTVM.nameTVM,
        TVMNumber: editingTVM.TVMNumber,
        version: editingTVM.TVMVersion,
        macAddress: editingTVM.MACAddress,
        msgOutOfService: editingTVM.msgOutOfService,
        idStation: editingTVM.idStation,
        isActive: editingTVM.isActive,
        alarmDelay: editingTVM.alarmDelay,
        screenTimeout: editingTVM.screenTimeout,
        soundVolume: editingTVM.soundVolume,
        encrypted: editingTVM.encrypted,
        tenantsDB: currentTenant
    });

    const [stationsData, setStationsData] = useState([]);
    const [error, setError] = useState(null);
    const [passwordType, setPasswordType] = useState("password");
    const [verificationPasswordType, setVerificationPasswordType] = useState("password");
    const [labelsData, setLabelsData] = useState([]);
    const [maximumChange, setMaximumChange] = useState(200.00);
    const language = JSON.parse(localStorage.getItem('language'));
    const [dataError, setDataError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                setMaximumChange(editingTVM.maximumChangeAvailable);
                const response = await getStationsData();
                setStationsData(response);
                const labelsResponse = await getLabelsData();
                setLabelsData(labelsResponse);
                setEditingTVM();
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, [editingTVM]);

    async function getStationsData() {
        const { data, error } = await getStations(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    }

    async function getLabelsData() {
        const { data, error } = await getLabelsTableById(currentTenant, editingTVM.idTvm);
        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            const coins = [];
            const bills = [];
            const devices = [];
            data.BillsLabel.map(bill => {
                bills.push({
                    idTvmBills: bill.idTvmBills,
                    EnableBills: bill.EnableBills
                });
            }
            );
            data.coinsLabel.map(coin => {
                coins.push({
                    idTvmCoins: coin.idTvmCoins,
                    EnableCoins: coin.EnableCoins
                });
            }
            );
            data.DevicesLabel.map(device => {
                devices.push({
                    idDeviceAlarms: device.idDeviceAlarms,
                    enableDevice: device.enableDevice,
                    Port: device.port || 'none',
                    name: device.name
                });
            }
            );
            data.DevicesLabel = devices;
            setUserTVM({ ...userTVM, coinsList: coins, billsList: bills, deviceList: devices });
            return data;
        }
    }
    const handleStartupFileAndSave = async () => {
        setIsLoading(true);

        const coins = labelsData.BillsLabel;
        const bills = labelsData.coinsLabel;
        const devices = labelsData.DevicesLabel;

        const newTVM = { ...userTVM };
        newTVM.maximumChangeAvailable = maximumChange;
        newTVM.coinsList = coins;
        newTVM.billsList = bills;
        newTVM.deviceList = devices;

        await updateTVM(newTVM, editingTVM.idTvm);

        const { data } = await getGlobalConfig(currentTenant);
        const EndPointWS = data.globalConfig[0].EndPointWS;
        const station = stationsData.find(station => station.idStation === newTVM.idStation);
        const startup = {
            idTvm: editingTVM.idTvm,
            userName: newTVM.username,
            passwordTVM: newTVM.encrypted,
            TVMNumber: newTVM.TVMNumber,
            tenantsDBName: currentTenant,
            idStation: newTVM.idStation,
            stations: station.stationName,
            EndPointWS: EndPointWS
        }
        const xmlData = jsonToXml(startup, 'tvmConfig');
        downloadXMLFile(xmlData, 'Startup.xml');
        const reloadData = await getData();
        setData(reloadData);
        onHide();
        clearTVM();
        setIsLoading(false);
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);

            const coins = labelsData.coinsLabel;
            const bills = labelsData.BillsLabel;
            const devices = labelsData.DevicesLabel;

            const newTVM = { ...userTVM };
            newTVM.maximumChangeAvailable = maximumChange;
            newTVM.coinsList = coins;
            newTVM.billsList = bills;
            newTVM.deviceList = devices;

            const response = await updateTVM(newTVM, editingTVM.idTvm);
            if (response.error !== undefined) {
                const error = ErrorCases(response.error);
                setDataError(error);
                return;
            }
            const data = await getData();
            setData(data);
            onHide();
        } catch (error) {
            console.error(error);
            setDataError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        onHide();
    };

    const changePasswordType = (isVerification) => {
        if (isVerification) {
            verificationPasswordType === "password" ? setVerificationPasswordType("text") : setVerificationPasswordType("password");
        } else {
            passwordType === "password" ? setPasswordType("text") : setPasswordType("password");
        }
    };

    const handleChangeChecked = (table, key, id, enable) => {
        const newTable = [...labelsData[table]];
        const newCheckIndex = newTable.findIndex(check => check[key] === id);
        if (newCheckIndex !== -1) {
            newTable[newCheckIndex] = {
                ...newTable[newCheckIndex],
                [enable]: newTable[newCheckIndex][enable] === 1 ? 0 : 1
            };
            setLabelsData(prevState => ({
                ...prevState,
                [table]: newTable
            }));
        }
    };

    const setEditingTVM = () => {
        setUserTVM({
            ...userTVM,
            username: editingTVM.userName,
            TVMName: editingTVM.nameTVM,
            TVMNumber: editingTVM.TVMNumber,
            version: editingTVM.TVMVersion,
            macAddress: editingTVM.MACAddress,
            msgOutOfService: editingTVM.msgOutOfService,
            idStation: editingTVM.idStation,
            isActive: editingTVM.isActive,
            alarmDelay: editingTVM.alarmDelay,
            screenTimeout: editingTVM.screenTimeout,
            soundVolume: editingTVM.soundVolume,
            encrypted: editingTVM.encrypted,
            tenantsDB: currentTenant
        });
    }

    const clearTVM = () => {
        setUserTVM({
            username: '',
            password: '',
            TVMName: '',
            TVMNumber: 0,
            version: '',
            macAddress: '',
            msgOutOfService: '',
            idStation: 0,
            isActive: 1,
            maximumChangeAvailable: 0.00,
            alarmDelay: 0,
            screenTimeout: 0,
            soundVolume: 50,
            encrypted: '',
            tenantsDB: currentTenant,
            coinsList: [],
            billsList: [],
            deviceList: []
        });
    };

    const handleAssignPort = (idDevice, newPort) => {
        setDataError('');
        const newTable = labelsData.DevicesLabel;

        const isPortTaken = newTable.some(device => device.Port === newPort);
        if (isPortTaken) {
            setDataError(language.PortSelected);
            return;
        }

        const newPortIndex = newTable.findIndex(port => port.idDeviceAlarms === idDevice);
        if (newPortIndex !== -1) {
            newTable[newPortIndex] = {
                ...newTable[newPortIndex],
                "Port": newPort
            };
            setLabelsData(prevState => ({
                ...prevState,
                deviceList: newTable
            }));
        }
    }

    function jsonToXml(json, rootElement = 'root') {
        let xml = `<${rootElement}>`;

        for (const key in json) {
            if (json[key] instanceof Object && !Array.isArray(json[key])) {
                xml += jsonToXml(json[key], key);
            } else if (Array.isArray(json[key])) {
                json[key].forEach(item => {
                    xml += jsonToXml(item, key);
                });
            } else {
                xml += `<${key}>${json[key]}</${key}>`;
            }
        }

        xml += `</${rootElement}>`;
        return xml;
    }

    function downloadXMLFile(xmlContent, fileName) {
        const blob = new Blob([xmlContent], { type: 'application/xml' });
        saveAs(blob, fileName);
    }

    const handleChangeNumber = (e) => {
        const inputValue = e.target.value;

        if (inputValue >= 1 && inputValue <= 99999) {
            setUserTVM({ ...userTVM, TVMNumber: inputValue });
        }
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Body>
                    <div className="modal-scroll-container">
                        <Tabs defaultActiveKey="general" id="modal-tabs" className="mb-3 modal-tabs active-title">
                            <Tab eventKey="general" title={language.General}>
                                <div className='modal__subtitle'>{language.EditUserTVM}</div>
                                <div className='inputs-row'>
                                    <div className='input-name'>
                                        <label className='input__label'>{language.TVMName}</label> <br />
                                        <input placeholder={language.EnterTVMName}
                                            className='module__input-search modal__text-box email-input'
                                            onChange={evt => setUserTVM({ ...userTVM, TVMName: evt.target.value })}
                                            value={userTVM.TVMName}
                                        ></input>
                                    </div>

                                    <div className=''>
                                        <label className='input__label'>{language.Station}</label> <br />
                                        <select onChange={evt => setUserTVM({ ...userTVM, idStation: evt.target.value })}
                                            value={userTVM.idStation}
                                            className='module__input-search'>
                                            <option>{language.SelectAStation}</option>
                                            {stationsData.length > 0 && stationsData.map(station => {
                                                return <option key={station.idStation} id={station.idStation} value={station.idStation}>{station.stationName}</option>
                                            })}
                                        </select>
                                    </div>


                                </div>
                                <div className='inputs-row'>
                                    <div className='modal__tvm-number-input'>
                                        <label className='input__label'>{language.TVMNumber}</label> <br />
                                        <input
                                            className='module__input-search'
                                            onChange={handleChangeNumber}
                                            type='number'
                                            value={userTVM.TVMNumber}
                                        ></input>
                                    </div>

                                    <div className='tvmversion__input mx-3'>
                                        <label className='input__label'>{language.TVMVersion}</label> <br />
                                        <input
                                            className='module__input-search modal__text-box email-input'
                                            onChange={evt => setUserTVM({ ...userTVM, version: evt.target.value })}
                                            value={userTVM.version}
                                            disabled
                                        ></input>
                                    </div>

                                    <div className='input-name'>
                                        <label className='input__label'>{language.UserName}</label> <br />
                                        <input placeholder={language.EnterUsername}
                                            className='module__input-search modal__text-box email-input'
                                            onChange={evt => setUserTVM({ ...userTVM, username: evt.target.value })}
                                            value={userTVM.username}
                                            disabled
                                        ></input>
                                    </div>
                                </div>

                                <div className='inputs-row__no-justified'>
                                    <div className='input-name'>
                                        <label className='input__label'>{language.MACAddress}</label> <br />
                                        <input placeholder={language.EnterMACAddress}
                                            className='module__input-search modal__text-box email-input'
                                            onChange={evt => setUserTVM({ ...userTVM, macAddress: evt.target.value })}
                                            value={userTVM.macAddress}
                                        ></input>
                                    </div>
                                    <div className='input-name'>
                                        <label className='input__label'>{language.msgOutOfService}</label> <br />
                                        <input placeholder={language.msgOutOfService}
                                            className='module__input-search'
                                            maxLength={100}
                                            onChange={evt => setUserTVM({ ...userTVM, msgOutOfService: evt.target.value })}
                                            value={userTVM.msgOutOfService}
                                        ></input>
                                    </div>
                                    <div className='active-input'>
                                        <label className='input__label'>{language.Active}</label> <br />
                                        <img src={userTVM.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                            className='table__checkbox'
                                            onClick={() => userTVM.isActive === 1 ? setUserTVM({ ...userTVM, isActive: 0 }) : setUserTVM({ ...userTVM, isActive: 1 })} />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="devices" title={language.Devices}>
                                <div className='modal__subtitle'>{language.CashConfiguration}</div>
                                <div className='inputs-row'>
                                    <div className='input-name'>
                                        <label className='input__label'>{language.Coins}</label> <br />
                                        <div className='coins-input'>
                                            {labelsData.coinsLabel && labelsData.coinsLabel.length > 0 && labelsData.coinsLabel.map((coin) => {
                                                return (
                                                    <div id='idTvmCoins' className='coin-box'>
                                                        <div className='modal__denomination'>{coin.labelName}</div>
                                                        <img src={coin.EnableCoins === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                                            className='modal__checkbox'
                                                            onClick={() => handleChangeChecked('coinsLabel', 'idTvmCoins', coin.idTvmCoins, 'EnableCoins')} />
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>

                                        <label className='input__label'>{language.Bills}</label> <br />
                                        <div className='coins-input'>
                                            {labelsData.BillsLabel && labelsData.BillsLabel.length > 0 && labelsData.BillsLabel.map((bill) => {
                                                return (
                                                    <div id='idTvmBills' className='coin-box'>
                                                        <div className='modal__denomination'>{bill.labelName}</div>
                                                        <img src={bill.EnableBills === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                                            className='modal__checkbox'
                                                            onClick={() => handleChangeChecked('BillsLabel', 'idTvmBills', bill.idTvmBills, 'EnableBills')} />
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>

                                        <label className='input__label'>{language.MaximumChangeAvailable}</label> <br />
                                        <CurrencyInput
                                            placeholder={language.EnterProductPrice}
                                            className='module__input-search'
                                            defaultValue={maximumChange}
                                            decimalsLimit={2}
                                            onValueChange={(value, names, values) => setMaximumChange(values.float)}
                                            decimalScale={2}
                                            maxLength={7}
                                            intlConfig={{ locale: languageCode, currency: currency.currency }}
                                        />
                                    </div>
                                </div>
                                <hr class="modal-separator"></hr>
                                <div className='modal__subtitle'>{language.TVMConfiguration}</div>
                                <div className='inputs-row'>

                                    <div>
                                        <label className='input__label'>{language.AlarmDelay}</label> <br />
                                        <input type='number'
                                            className='module__input-search'
                                            onChange={evt => setUserTVM({ ...userTVM, alarmDelay: evt.target.value })}
                                            value={userTVM.alarmDelay}
                                        ></input>
                                    </div>

                                    <div>
                                        <label className='input__label'>{language.ScreenTimeout}</label> <br />
                                        <input type='number'
                                            className='module__input-search'
                                            onChange={evt => setUserTVM({ ...userTVM, screenTimeout: evt.target.value })}
                                            value={userTVM.screenTimeout}
                                        ></input>
                                    </div>


                                </div>

                                <div>
                                    <label className='input__label'>{language.SoundVolume}</label> <br />
                                    <div className='modal-range__box'>
                                        <div className='modal-range__control' onClick={() => setUserTVM({ ...userTVM, soundVolume: userTVM.soundVolume - 1 })}>-</div>
                                        <Form.Range
                                            onChange={evt => setUserTVM({ ...userTVM, soundVolume: evt.target.value })}
                                            defaultValue={userTVM.soundVolume}
                                        />
                                        <div className='modal-range__value'>{userTVM.soundVolume}</div>
                                        <div className='modal-range__control' onClick={() => setUserTVM({ ...userTVM, soundVolume: userTVM.soundVolume + 1 })}>+</div>
                                    </div>
                                </div>

                                <label className='input__label'>{language.AlarmsTable}</label> <br />
                                <div className='coins-input'>
                                    {labelsData.DevicesLabel && labelsData.DevicesLabel.length > 0 && labelsData.DevicesLabel.map((device) => {
                                        return (
                                            <div id='idDeviceAlarms' className='alarm-box'>
                                                <img src={device.enableDevice === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                                    className='modal__checkbox'
                                                    onClick={() => handleChangeChecked('DevicesLabel', 'idDeviceAlarms', device.idDeviceAlarms, 'enableDevice')} />
                                                <div className='modal__denomination'>{device.name}</div>
                                                <select className='modal__port-input'
                                                    onChange={(evt) => handleAssignPort(device.idDeviceAlarms, evt.target.value)}
                                                    value={device.Port || ''}>
                                                    <option>{language.SelectAPort}</option>
                                                    {Ports.map(port => (
                                                        <option key={port} value={port}>{port}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                            </Tab>
                        </Tabs>
                        {dataError && <div className='error-alert'>{dataError}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' style={{ fontSize: '12px' }} onClick={handleStartupFileAndSave}>
                        {language.StartupFile}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TVMEditModal;